<template>
  <v-container>
    <v-app-bar app color="white">
      <v-row align="center">
        <v-col cols="2">
          <v-app-bar-nav-icon
              class="d-flex"
              @click="$emit('toggleNavBar')"/>
        </v-col>

        <v-col cols="8">
          <v-toolbar-title
              v-if="this.merchant != null"
              class="text-center">
            گزارش پرداخت‌ها
          </v-toolbar-title>
        </v-col>

        <v-col cols="2">
          <v-spacer/>
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn depressed :icon="$vuetify.breakpoint.smAndDown" v-on="on">
                <v-icon class="mdi mdi-dots-vertical"></v-icon>
                <span class="pa-2 d-none d-md-flex">دانلود</span>
              </v-btn>
            </template>

            <v-list>
              <v-list-item :href="pastDaysPaymentsExportLink(30)">
                <v-list-item-title>اکسل ۳۰ روز اخیر</v-list-item-title>
              </v-list-item>
              <v-list-item :href="pastDaysPaymentsExportLink(180)">
                <v-list-item-title>اکسل ۱۸۰ روز اخیر</v-list-item-title>
              </v-list-item>
              <v-list-item :href="pastDaysPaymentsExportLink(365)">
                <v-list-item-title>اکسل ۳۶۵ روز اخیر</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
    </v-app-bar>

    <v-container>
      <v-card>
        <v-card-title>
          <v-breadcrumbs large>
            <v-breadcrumbs-item
                v-if="this.merchant != null" exact
                :to="{name: 'merchant_detail'}">
              {{ this.merchant.merchant.title }}
            </v-breadcrumbs-item>
            <v-icon class="mx-1 mdi mdi-slash-forward"></v-icon>
            <v-breadcrumbs-item>پرداخت‌ها</v-breadcrumbs-item>
          </v-breadcrumbs>
          <v-spacer></v-spacer>
          <v-row dense>
            <v-col cols=12 md=6 lg=4>
              <v-text-field
                v-model="filters.since"
                prepend-inner-icon="mdi-calendar-arrow-left"
                :class="{nonEmptyFilter : !isAppliedFilterEmpty('since'),
                        leftAlignedInput: true}"
                label="از تاریخ"
                hint="مثال: ۴:۴۵:۵۰ ۱۳۹۹/۰۲/۲۰"
                :rules="[validateSince]"
                clearable
                clear-icon="mdi-close-circle-outline"
                @keydown.enter="applyFilter"
                @click:clear="clearSince"
                />
            </v-col>
            <v-col cols=12 md=6 lg=4>
              <v-text-field
                v-model="filters.until"
                prepend-inner-icon="mdi-calendar-arrow-right"
                :class="{nonEmptyFilter : !isAppliedFilterEmpty('until'),
                        leftAlignedInput: true}"
                :style="{textAlign: 'left'}"
                label="تا تاریخ"
                :rules="[validateUntil]"
                hint="مثال: ۴:۴۵:۵۰ ۱۳۹۹/۰۲/۲۰"
                clearable
                clear-icon="mdi-close-circle-outline"
                @keydown.enter="applyFilter"
                @click:clear="clearUntil"
                />
            </v-col>
            <v-col cols=12 md=6 lg=4>
              <v-text-field
                v-model="filters.query"
                prepend-inner-icon="mdi-magnify"
                hint="شناسه خرید، شماره کارت، کد پیگیری"
                :class="{nonEmptyFilter : !isAppliedFilterEmpty('query')}"
                label="جستجو"
                clearable
                clear-icon="mdi-close-circle-outline"
                @keydown.enter="applyFilter"
                @click:clear="clearFilter"
                />
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <!-- <v-container> -->
            <v-card>
              <v-card-text class="teal lighten-4">
                <v-row class="teal lighten-4">
                  <v-col cols="auto" class="teal lighten-4">
                    مجموع پرداخت (ریال): <b><span> {{ toPersianDigits(formatNumber(fullTotal)) }} </span></b>
                  </v-col>
                  <v-col cols="auto" class="teal lighten-4">
                    مجموع قابل تسویه (ریال): <b><span> {{ toPersianDigits(formatNumber(fullTotal - fullWage)) }} </span></b>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          <!-- </v-container> -->
          <v-data-table
              :headers="headers"
              :items="payments"
              :loading="loading"
              :server-items-length="count"
              :items-per-page="limit"
              @update:items-per-page="updateLimit"
              @update:page="updatePage"
              :page.sync="currentPage"
              :footer-props="{'items-per-page-options': [5, 10, 50]}"
              disable-sort>

            <template v-slot:item.pay_time="{ item }">
              <div style="direction: ltr">
                {{ formatDate(item.pay_time) }}
              </div>
            </template>

            <template v-slot:item.total="{ item }">
              <div style="direction: ltr">
                {{ toPersianDigits(formatNumber(item.total)) }}
              </div>
            </template>

            <template v-slot:item.settleable="{ item }">
              <div style="direction: ltr">
                {{ toPersianDigits(formatNumber(item.total - item.wage)) }}
              </div>
            </template>

            <template v-slot:item.reference="{ item }">
              <v-tooltip left v-if="item.reference.length > 10">
                <template v-slot:activator="{ on }">
                  <div v-on="on" class="referenceCode"
                       v-bind:style="{ maxWidth: largerThanMd ? '7em' : '10em' }"
                       >
                    {{ item.reference }}
                  </div>
                </template>
                 <span>
                   {{ item.reference }}
                 </span>
              </v-tooltip>

              <div v-else class="referenceCode">
                {{ item.reference }}
              </div>
            </template>

            <template v-slot:item.pay_pan="{ item }">
              <div style="direction: ltr">
                <code>{{ formatPan(item.pay_pan) }}</code>
              </div>
            </template>

            <template v-slot:item.pay_trace="{ item }">
              <div style="direction: ltr">
                {{ toPersianDigits(item.pay_trace) }}
              </div>
            </template>

            <template v-slot:item.tns_state="{ item }">
              <div :class="[transferStateColor(item.tns_state)]+'--text text--darken-2'">
                {{ transferState(item.tns_state) }}
              </div>
            </template>

            <template v-slot:item.tns_estimate="{ item }">
              <div style="direction: ltr">
                {{ formatDate(item.tns_estimate) }}
              </div>
            </template>

            <template v-slot:item.tns_trace="{ item }">
              <div style="direction: ltr">
                {{ toPersianDigits(item.tns_trace) }}
              </div>
            </template>

          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>

  </v-container>
</template>

<script>
  import {mapGetters} from 'vuex'
  import {toPersianDigits} from '../base/number'
  import {parseJalaaliDateTimeString} from '../base/date'
  import {formatNumber, formatDate, formatPan} from '../base/format'

  export default {
    name: "MerchantPaymentsDetails",
    data: () => ({
      merchantId: null,
      merchant: null,
      filters: {'query': '', 'since': '', 'until': ''},
      sinceTimestamp: 0,
      untilTimestamp: 0,
      loading: true,
      limit: 10,
      offset: 0,
      count: 0,
      fullTotal: 0,
      fullWage: 0,
      currentPage: 1,
      headers: [
        {text: 'زمان پرداخت', value: 'pay_time', align: 'center'},
        {text: 'مبلغ (ریال)', value: 'total', align: 'right'},
        {text: 'قابل تسویه (ریال)', value: 'settleable', align: 'right'},
        {text: 'شناسه خرید', value: 'reference', align: 'right'},
        {text: 'شماره کارت', value: 'pay_pan', align: 'center'},
        {text: 'کدپیگیری پرداخت', value: 'pay_trace', align: 'center'},
        {text: 'وضعیت تسویه', value: 'tns_state', align: 'center'},
        {text: 'زمان تخمینی تسویه', value: 'tns_estimate', align: 'center'},
        {text: 'کدپیگیری تسویه', value: 'tns_trace', align: 'center'},
      ],
      payments: [],
    }),
    computed: {
      ...mapGetters({
        sessionKey: 'sessionKey',
        getMerchant: 'merchant/merchant',
        getPayments: 'merchant/payment/detail/get',
      }),
      largerThanMd() {
        switch(this.$vuetify.breakpoint.name) {
          case 'xs':
            return false
          case 'sm':
            return false
          default:
            return true
        }
      }
    },
    methods: {
      toPersianDigits,
      formatNumber,
      formatDate,
      formatPan,
      isAppliedFilterEmpty(filterName) {
        const value = this.filters[filterName];
        return value === '' || value === null;
      },
      updateLimit(limit) {
        this.limit = limit
        this.loadPayments()
      },
      updatePage(page) {
        this.offset = (page - 1) * this.limit
        this.loadPayments()
      },
      applyFilter() {
        this.offset = 0
        this.currentPage = 1
        this.loadPayments()
      },
      clearFilter() {
        this.filters.query = ''
        this.applyFilter()
      },
      clearSince() {
        this.filters.since =''
        this.sinceTimestamp = 0
        this.applyFilter()
      },
      clearUntil() {
        this.filters.until = ''
        this.untilTimestamp = 0
        this.applyFilter()
      },
      validateSince(value) {
        try {
          this.sinceTimestamp = parseJalaaliDateTimeString(value);
          return true;
        } catch (e) {
          this.sinceTimestamp = 0;
          return "زمان نامعتبر است";
        }
      },
      validateUntil(value) {
        try {
          this.untilTimestamp = parseJalaaliDateTimeString(value, true);
          return true;
        } catch (e) {
          this.untilTimestamp = 0;
          return "زمان نامعتبر است"
        }
      },
      loadPayments() {
        this.loading = true
        let tag = this.merchantId + '/payments/details';
        let params = {
          merchant_id: this.merchantId,
          limit: this.limit,
          offset: this.offset,
        }
        if (this.filters.query) {
          params['filter'] = this.toEnglishDigits(this.filters.query)
        }
        if (this.sinceTimestamp) {
          params['since'] = this.sinceTimestamp
        }
        if (this.untilTimestamp) {
          params['until'] = this.untilTimestamp
        }
        this.$store.dispatch('merchant/payment/detail/load', {
          tag: tag,
          params: params,
        }).then(() => {
          let payments = this.getPayments(tag)
          if (payments == null) {
            return
          }

          this.count = payments.info.full_count
          this.fullTotal = payments.summary.full_total
          this.fullWage = payments.summary.full_wage
          this.payments = payments.rows
          this.loading = false
        })
      },
      transferState(state) {
        switch (state) {
          case 'done':
            return 'تسویه شده'
          case 'shaparak_ongoing':
            return 'در حال تسویه'
          case 'shaparak_error':
            return 'خطا در تسویه'
          case 'shaparak_wait':
            return 'برگشت خورده'
          default:
            return ''
        }
      },
      transferStateColor(state) {
        switch (state) {
          case 'done':
            return 'green'
          case 'shaparak_error':
            return 'red'
          case 'shaparak_wait':
            return 'orange'
          default:
            return ''
        }
      },
      pastDaysPaymentsExportLink(days) {
        let now = new Date()
        let startDay = new Date(now)
        startDay.setDate(startDay.getDate() - days)

        const l = new URL(
          '/api/owner/report_payment_detail',
          window.location.href,
        )
        const since = Math.floor(startDay.getTime() / 1000)
        l.searchParams.set('key', this.sessionKey)
        l.searchParams.set('merchant_id', this.merchantId)
        l.searchParams.set('since', since)
        l.searchParams.set('offset', '0')
        l.searchParams.set('limit', '-1') // no limit
        l.searchParams.set('format', 'excel')

        return l.toString()
      },
    },
    created() {
      this.merchantId = this.$route.params.id

      this.$store.subscribe(mutation => {
        if (mutation.type === 'merchant/set') {
          this.merchant = this.getMerchant(this.merchantId)
          this.loadPayments()
        }
      })

      this.merchant = this.getMerchant(this.merchantId)
      if (this.merchant != null) {
        this.loadPayments()
      }
    },
    mounted() {
      this.$emit('navState', 'merchant_payments_detail')
    }
  }
</script>

<style scoped>

.nonEmptyFilter {
  background: #FFF59D;
}

.referenceCode {
  direction: ltr;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.leftAlignedInput >>> input {
  direction: ltr;
}

</style>
