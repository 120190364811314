<template>
  <v-dialog
      :value="value"
      @input="$emit('input', $event)"
      max-width="600px">
    <v-card>
      <v-card-title class="text-center">
        <v-container>
          <v-row justify="center">
            <span class="headline">آدرس کیف پول</span>
          </v-row>
        </v-container>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <div>
                آدرس زیر برای کیف پول
                <strong>{{title}}</strong>
                ساخته شده است. بدون نگرانی امنیتی می‌توانید آن را در اختیار کسی قرار دهید که می‌خواهد به کیف پول شما واریز انجام دهد.
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                  id="wallet-address"
                  readonly
                  class="ltr"
                  v-model="address"
                  solo>
                <template v-slot:append>
                  <v-btn class="me-2" icon color="success" @click="copyKey">
                    <v-icon class="mdi mdi-vector-arrange-above mdi-rotate-90"/>
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="0" md="4">
            </v-col>
            <v-col cols="12" md="4">
              <v-btn @click="close()" color="success"
                     block>
                بسیار خب
              </v-btn>
            </v-col>
            <v-col cols="0" md="4">
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: "DialogٌWalletAddress",
    props: [
      'value',
      'title',
      'address',
    ],
    data: () => ({}),
    methods: {
      close() {
        this.$emit('input', false)
      },
      copyKey: function() {
        const e = document.getElementById('wallet-address')
        e.select()
        e.setSelectionRange(0, this.address.length)

        this.$copyText(this.address).then(function () {
          e.setSelectionRange(0, 0)
        })

        this.$store.commit('showCopiedSnackbar')
      },
    },
  }
</script>

<style scoped>

</style>