<template>
  <v-container>
    <v-app-bar app color="white">
      <v-row align="center">
        <v-col cols="2">
          <v-app-bar-nav-icon
              class="d-flex d-md-none"
              @click="$emit('toggleNavBar')"/>
        </v-col>

        <v-col cols="8">
          <v-toolbar-title
              v-if="this.merchant != null"
              class="text-center">
            گزارش تراکنش‌های کیف پول
          </v-toolbar-title>
        </v-col>

        <v-col cols="2">
          <v-spacer/>
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn depressed :icon="$vuetify.breakpoint.smAndDown" v-on="on">
                <v-icon class="mdi mdi-dots-vertical"></v-icon>
                <span class="pa-2 d-none d-md-flex">دانلود</span>
              </v-btn>
            </template>

            <v-list>
              <v-list-item :href="pastDaysWalletTnxExportLink(30)">
                <v-list-item-title>اکسل ۳۰ روز اخیر</v-list-item-title>
              </v-list-item>
              <v-list-item :href="pastDaysWalletTnxExportLink(180)">
                <v-list-item-title>اکسل ۱۸۰ روز اخیر</v-list-item-title>
              </v-list-item>
              <v-list-item :href="pastDaysWalletTnxExportLink(365)">
                <v-list-item-title>اکسل ۳۶۵ روز اخیر</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
    </v-app-bar>

    <v-container>
      <v-card>
        <v-card-title>
          <v-breadcrumbs large>
            <v-breadcrumbs-item
                v-if="this.merchant != null" exact
                :to="{name: 'merchant_detail'}">
              {{ this.merchant.merchant.title }}
            </v-breadcrumbs-item>
            <v-icon class="mx-1 mdi mdi-slash-forward"></v-icon>
            <v-breadcrumbs-item>تراکنش‌های کیف پول</v-breadcrumbs-item>
          </v-breadcrumbs>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="filter"
            prepend-inner-icon="mdi-magnify"
            :class="{nonEmptyFilter : !isAppliedFilterEmpty}"
            label="جستجو"
            clearable
            clear-icon="mdi-close-circle-outline"
            single-line
            hide-details
            @keydown.enter="applyFilter"
            @click:clear="clearFilter"
          ></v-text-field>
        </v-card-title>
        <v-card-text>
          <v-data-table
              :headers="headers"
              :items="wallet_tnxs"
              :loading="loading"
              :server-items-length="count"
              :items-per-page="limit"
              @update:items-per-page="updateLimit"
              @update:page="updatePage"
              :page.sync="currentPage"
              :footer-props="{'items-per-page-options': [5, 10, 50]}"
              disable-sort>

            <template v-slot:item.RequestTime="{ item }">
              <div class="text-right" style="direction: ltr">
                {{ formatDate(item.RequestTime) }}
              </div>
            </template>

            <template v-slot:item.SettlementEstimate="{ item }">
              <div class="text-right" style="direction: ltr">
                {{ formatDate(item.SettlementEstimate) }}
              </div>
            </template>

            <template v-slot:item.Iban="{ item }">
              <div class="text-right" style="direction: ltr">
                <code>{{ item.Iban }}</code>
              </div>
            </template>

            <template v-slot:item.Name="{ item }">
              <div class="text-right" style="direction: ltr">
                {{ item.Name }}
              </div>
            </template>

            <template v-slot:item.Amount="{ item }">
              <div :class="['text-right', item.Amount >= 0 ? 'p-money' : 'n-money']" style="direction: ltr">
                {{ toPersianDigits(formatMoney(item.Amount)) }}
              </div>
            </template>

            <template v-slot:item.Wage="{ item }">
              <div class="text-right n-money" style="direction: ltr">
                {{ toPersianDigits(formatMoney(-item.Wage)) }}
              </div>
            </template>

            <template v-slot:item.Trace="{ item }">
              <div v-if="item.Trace == '' && item.Type == 'withdraw'" :class="[withdrawStateColor(item.State)]+'--text'">
                {{ withdrawState(item.State) }}
              </div>
              <div v-else>
                {{ toPersianDigits(item.Trace) }}
              </div>
            </template>
            <template v-slot:item.TransactionId="{ item }">
              <v-tooltip v-if="item.Trace != ''" left>
                <template v-slot:activator="{ on }">
                  <v-btn
                    icon
                    v-on="on"
                    class="ma-2"
                    :href="getReceiptLink(item.TransactionId)"
                    target="_blank"
                    color="grey"
                    dark>
                    <v-icon>
                      fas fa-receipt
                    </v-icon>
                </v-btn>
                </template>
                <span>
                  رسید
                </span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>

  </v-container>
</template>

<script>
  import {mapGetters} from 'vuex'
  import {withdrawState, withdrawStateColor} from '../base/wallet'
  import {toPersianDigits} from '../base/number'
  import {formatNumber, formatMoney, formatDate, formatPan} from '../base/format'

  export default {
    name: "MerchantWalletTnxsReport",
    data: () => ({
      merchantId: null,
      merchant: null,
      filter: '',
      isAppliedFilterEmpty: true,
      loading: true,
      limit: 10,
      offset: 0,
      count: 0,
      currentPage: 1,
      headers: [
        {text: 'تاریخ ثبت', value: 'RequestTime'},
        {text: 'زمان تقریبی تسویه', value: 'SettlementEstimate'},
        {text: 'شبا', value: 'Iban'},
        {text: 'نام', value: 'Name'},
        {text: 'مبلغ (ریال)', value: 'Amount'},
        {text: 'کارمزد (ریال)', value: 'Wage'},
        {text: 'کد رهگیری', value: 'Trace'},
        {text: '', value: 'TransactionId'},
      ],
      wallet_tnxs: [],
    }),
    computed: {
      ...mapGetters({
        sessionKey: 'sessionKey',
        getMerchant: 'merchant/merchant',
        getWalletTnxs: 'merchant/walletReport/get',
      }),
    },
    methods: {
      toPersianDigits,
      formatNumber,
      formatMoney,
      formatDate,
      formatPan,
      withdrawState,
      withdrawStateColor,

      updateLimit(limit) {
        this.limit = limit
        this.loadWalletTnx()
      },
      updatePage(page) {
        this.offset = (page - 1) * this.limit
        this.loadWalletTnx()
      },
      applyFilter() {
        this.offset = 0
        this.currentPage = 1
        this.loadWalletTnx()

        this.isAppliedFilterEmpty = (this.filter == null || this.filter == '')
      },
      clearFilter() {
        this.filter = ''
        this.applyFilter()
      },
      loadWalletTnx() {
        this.loading = true
        let tag = this.merchantId + '/wallet_tnxs/details'
        this.$store.dispatch('merchant/walletReport/load', {
          tag: tag,
          params: {
            merchant_id: this.merchantId,
            filter: this.toEnglishDigits(this.filter ? this.filter : ''),
            since: new Date(0),
            until: new Date(),
            offset: this.offset,
            limit: this.limit,
          }
        }).then(() => {
          let report = this.getWalletTnxs(this.merchantId)
          if (report == null) {
            return
          }

          this.count = report.Info.full_count
          this.wallet_tnxs = report.WalletTnxs
          this.loading = false
        })
      },
      pastDaysWalletTnxExportLink(days) {
        let now = new Date()
        let startDay = new Date(now)
        startDay.setDate(startDay.getDate() - days)

        const l = new URL(
          '/api/owner/report_wallet_summary',
          window.location.href,
        )
        l.searchParams.set('key', this.sessionKey)
        l.searchParams.set('merchant_id', this.merchantId)
        l.searchParams.set('since', startDay.toISOString())
        l.searchParams.set('until', now.toISOString())
        l.searchParams.set('offset', '0')
        l.searchParams.set('limit', '-1') // no limit
        l.searchParams.set('format', 'excel')

        return l.toString()
      },
      getReceiptLink(transactionId) {
        return `/api/owner/wallet_tnx_receipt?transaction_id=${transactionId}`
      },
    },
    created() {
      this.merchantId = this.$route.params.id

      this.$store.subscribe(mutation => {
        if (mutation.type === 'merchant/set') {
          this.merchant = this.getMerchant(this.merchantId)
          this.loadWalletTnx()
        }
      })

      this.merchant = this.getMerchant(this.merchantId)
      if (this.merchant != null) {
        this.loadWalletTnx()
      }
    },
    mounted() {
      this.$emit('navState', 'merchant_wallet_tnxs_detail')
    }
  }
</script>

<style scoped>

.nonEmptyFilter {
  background: #FFF59D;
}

.v-btn--icon.v-size--default .v-icon {
  font-size: 16px;
}

</style>
