<template>
  <v-container>

    <v-app-bar app>
      <v-row align="center">
        <v-col cols="2" md="4">
          <v-app-bar-nav-icon
              class="d-flex d-md-none"
              @click="$emit('toggleNavBar')"/>
        </v-col>

        <v-col cols="8" md="4">
          <v-toolbar-title class="text-center">فروشگاه‌ها</v-toolbar-title>
        </v-col>

        <v-col cols="2" md="4">
          <v-row justify="end" class="pa-4">
            <v-btn depressed :icon="$vuetify.breakpoint.smAndDown"
                   color="primary"
                   @click="newMerchantDialogStatus = true">
              <v-icon class="mdi mdi-plus-box"></v-icon>
              <span class="px-2 d-none d-md-flex">فروشگاه جدید</span>
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-app-bar>

    <new-merchant-dialog
        v-model="newMerchantDialogStatus"
        mode="new"/>

    <div key="loader" v-if="merchantsAreLoading">
      <v-card class="my-8 mx-auto" max-width="800px">
        <v-skeleton-loader width="100%" type="card-heading">
        </v-skeleton-loader>
      </v-card>
      <v-card class="my-8 mx-auto" max-width="800px">
        <v-skeleton-loader width="100%" type="card-heading">
        </v-skeleton-loader>
      </v-card>
      <v-card class="my-8 mx-auto" max-width="800px">
        <v-skeleton-loader width="100%" type="card-heading">
        </v-skeleton-loader>
      </v-card>
    </div>

    <div v-else-if="merchants.length === 0"
         class="d-flex flex-column justify-center"
         style="height: 50vh">
      <div class="display-1 text-center ma-2">فروشگاهی ندارید</div>
      <div class="subtitle text-center ma-2">
        با زدن دکمه «
        <v-icon class="mdi mdi-plus-box" color="primary"></v-icon>
        فروشگاه جدید»
        <br>
        اولین فروشگاه خود را بسازید.
      </div>
    </div>

    <merchant-summary
        v-else
        v-for="m in merchants" :key="m.merchant.merchant_id"
        :merchant-id="m.merchant.merchant_id"
        class="my-6 mx-auto" max-width="800px">
    </merchant-summary>

  </v-container>
</template>

<script>
  import {mapGetters} from 'vuex'
  import NewMerchantDialog from './DialogMerchantNew'
  import MerchantSummary from './PanelMerchantSummary'

  export default {
    name: "panel-merchants",
    components: {
      NewMerchantDialog,
      MerchantSummary,
    },
    data: () => ({
      newMerchantDialogStatus: false,
    }),
    computed: {
      ...mapGetters('merchant', {
        merchantsAreLoading: 'isLoading',
        merchants: 'merchants',
      }),
    },
    watch: {
      newMerchantDialogStatus(status) {
        if (status) {
          this.$router.push({query: {new_merchant: null}})
        } else {
          this.$router.push({query: {new_merchant: undefined}})
        }
      },
    },
    mounted() {
      this.$emit('navState', 'merchants')
    }
  }
</script>

<style scoped>
</style>