<template>
  <v-container>

    <v-app-bar app color="white">
      <v-row align="center">
        <v-col cols="2">
          <v-app-bar-nav-icon
              class="d-flex d-md-none"
              @click="$emit('toggleNavBar')"/>
        </v-col>

        <v-col cols="8">
          <v-toolbar-title class="text-center">
            پشتیبانی
          </v-toolbar-title>
        </v-col>

        <v-col cols="2">
        </v-col>
      </v-row>
    </v-app-bar>

    <v-container>
      <v-card class="white--text" flat color="rgb(24,152,155)">

        <v-container>
          <v-row>
            <v-col cols="12" md="6">
              <v-img
                  class="white--text align-end"
                  :min-height="$vuetify.breakpoint.smAndDown? '250px': '350px'"
                  position="center"
                  src="/img/support.webp"
              />
            </v-col>

            <v-col cols="12" md="6">
              <v-card-title class="headline">راه‌های ارتباطی</v-card-title>

              <v-card-text class="white--text body-1">
                <p>همکاران ما در بخش پشتیبانی مشتاقانه آماده
                  <strong>کمک</strong>
                  به
                  شما و <strong>حل مشکلات</strong> پیش آمده برای شما هستند.</p>

                <p>همچنین همیشه از شنیدن <strong>انتقادات سازنده</strong> و
                  <strong>پیشنهادات</strong>
                  شما خوشحال خواهیم شد.</p>

                <p>برای ارتباط با بخش پشتیبانی و مطرح کردن موضوع خود یکی از
                  راه‌های
                  زیر را انتخاب کنید.</p>
                  <div>
                    <v-btn rounded text color="white" block
                           href="mailto:info@bahamta.com">
                      <div class="d-flex justify-space-between"
                           style="width: 100%">
                        <div>
                          <v-icon class="mx-2">mdi mdi-at</v-icon>
                          <span class="subtitle-1">ایمیل</span>
                        </div>
                        <div class="body-1 mt-1"
                             style="text-transform: none; direction: ltr;">
                          info@bahamta.com
                        </div>
                      </div>
                    </v-btn>
                  </div>
                  <div>
                    <v-btn rounded text color="white" block
                           href="http://t.me/Bahamta_support">
                      <div class="d-flex justify-space-between"
                           style="width: 100%">
                        <div>
                          <v-icon class="mx-2 mb-2 mdi-rotate-315">
                            mdi mdi-send
                          </v-icon>
                          <span class="subtitle-1">تلگرام</span>
                        </div>
                        <div class="body-1 mt-1"
                             style="text-transform: none; direction: ltr;">
                          @Bahamta_Support
                        </div>
                      </div>
                    </v-btn>
                  </div>
                  <div>
                    <v-btn rounded text color="white" block
                           href="tel:+982191092580">
                      <div class="d-flex justify-space-between"
                           style="width: 100%">
                        <div>
                          <v-icon class="mx-2 mb-1">
                            mdi mdi-phone-classic
                          </v-icon>
                          <span class="subtitle-1">تماس تلفنی</span>
                        </div>
                        <div class="body-1 mt-1"
                             style="text-transform: none; direction: ltr;">
                             {{ toPersianDigits('021-91092580') }}
                        </div>
                      </div>
                    </v-btn>
                  </div>
              </v-card-text>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-container>
  </v-container>
</template>

<script>
  export default {
    name: "PanelSupport",
    mounted() {
      this.$emit('navState', 'support')
    }
  }
</script>

<style scoped>

</style>