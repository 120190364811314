<template>
  <v-container>
    <v-app-bar app color="white">
      <v-row align="center">
        <v-col cols="2">
          <v-app-bar-nav-icon
            class="d-flex d-md-none"
            @click="$emit('toggleNavBar')"
          />
        </v-col>

        <v-col cols="8">
          <v-toolbar-title class="text-center">
            کسب درآمد
          </v-toolbar-title>
        </v-col>

        <v-col cols="2"> </v-col>
      </v-row>
    </v-app-bar>

    <v-container>
      <v-card>
        <v-card-title>
          لینک معرفی به دیگران
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                با معرفی وب‌پی به مشتری‌های جدید، در صورتی که آنها از طریق لینک
                شما حساب کاربری خود را ایجاد کنند، به مدت یکسال ۲۰٪ از کارمزد آن کاربر به شما
                خواهد رسید. برای اطلاعات بیشتر به
                <router-link :to="{ name: 'affiliation_details' }">
                  اینجا
                </router-link>
                مراجعه کنید.
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field
                  id="affiliation-link"
                  readonly
                  class="ltr"
                  v-model="affiliationLink"
                  solo
                >
                  <template v-slot:append>
                    <v-btn class="me-2" icon color="success" @click="copyKey">
                      <v-icon
                        class="mdi mdi-vector-arrange-above mdi-rotate-90"
                      />
                    </v-btn>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-container>

    <v-container>
      <v-card>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="affiliations"
            :loading="loading"
            disable-pagination
            disable-filtering
            disable-sort
            hide-default-footer
          >
            <template v-slot:top>
              <div class="title mb-8">
                گزارش درآمد
              </div>

              <div class="ma-4">
                با رسیدن میزان درآمد به حداقل مبلغ
                <strong>۵۰۰ هزار ریال</strong> می‌توانید درخواست تسویه ارسال
                کنید. درخواست تسویه خود را از طریق ارتباط با
                <router-link :to="{ name: 'support' }">پشتیبانی</router-link>
                ارسال نمایید.
              </div>
            </template>

            <template v-slot:item.count="{ item }">
              <div style="direction: ltr">
                {{ toPersianDigits(formatNumber(item.count)) }}
              </div>
            </template>

            <template v-slot:item.income="{ item }">
              <div style="direction: ltr">
                {{ toPersianDigits(formatNumber(item.income)) }}
              </div>
            </template>

            <template v-slot:item.trace="{ item }">
              <div style="direction: ltr">
                {{ toPersianDigits(item.trace) }}
              </div>
            </template>

          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { toPersianDigits } from "../base/number";
import { formatNumber } from "../base/format";

export default {
  name: "Affiliation",
  data: () => ({
    loading: false,
    headers: [
      { text: "تعداد فروشگاه‌های فعال", value: "count", align: "center" },
      { text: "میزان درآمد (ریال)", value: "income", align: "center" },
      { text: "کد پیگیری تسویه", value: "trace", align: "center" },
    ],
    affiliations: [],
  }),
  computed: {
    ...mapGetters({
      affiliationCode: "profile/affiliationCode",
      affiliationData: "profile/affiliation/data",
    }),
    affiliationLink() {
      return "https://webpay.bahamta.com?rc=" + this.affiliationCode;
    },
  },
  methods: {
    toPersianDigits,
    formatNumber,
    copyKey: function() {
      const e = document.getElementById("affiliation-link");
      e.select();
      e.setSelectionRange(0, this.affiliationLink.length);

      this.$copyText(this.affiliationLink).then(function() {
        e.setSelectionRange(0, 0);
      });

      this.$store.commit("showCopiedSnackbar");
    },
  },
  created() {
    this.loading = true;
    this.$store.dispatch("profile/affiliation/load").then(() => {
      this.affiliations = this.affiliationData.rows;
      this.loading = false;
    });
  },
  mounted() {
    this.$emit("navState", "affiliation");
  },
};
</script>

<style scoped></style>
