import {toEnglishDigits} from "./number";

const postalCodeRegex = /^[0-9]{5}-?[0-9]{5}$/

function validPostalCode(code) {
  code = toEnglishDigits(code)
  if (!postalCodeRegex.test(code))
    return false

  // validate using https://fa.wikipedia.org/wiki/%DA%A9%D8%AF_%D9%BE%D8%B3%D8%AA%DB%8C
  let first5 = code.substring(0, 5)
  return (
    first5.indexOf('0') === -1 &&	// no 0 digit
    first5.indexOf('2') === -1 &&	// no 2 digit
    first5[4] != '5'				// not a 5 digit at position 5
  )
}

function validPostalCodeRule(code) {
  if (validPostalCode(code))
    return true
  return 'کدپستی نامعتبر است'
}

export {
  validPostalCode,
  validPostalCodeRule,
}