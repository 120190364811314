<template>
  <div>
    <v-dialog
        :value="value"
        @input="$emit('input', $event)"
        max-width="600px">
      <v-card>
        <v-form v-model="valid" class="px-md-4 centered-input"
                ref="newMerchantForm">
          <v-card-title class="text-center">
            <v-container>
              <v-row justify="center">
                <span class="headline">{{ headline }}</span>
              </v-row>
            </v-container>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-subheader>
                <v-spacer></v-spacer>
                مشخصات فروشگاه
                <v-spacer></v-spacer>
              </v-subheader>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                      label="عنوان"
                      v-model="title"
                      :rules="titleRule"
                      required
                      outlined
                      dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                      class="ltr-input"
                      label="وب سایت"
                      v-model="website"
                      :rules="websiteRule"
                      required
                      outlined
                      dense
                      validate-on-blur
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                      class="ltr-input"
                      label="تلفن ثابت"
                      v-model="phone"
                      :rules="phoneRule"
                      placeholder="۰۲۱-۱۲۳۴۵۶۷۸"
                      required
                      outlined
                      dense
                      validate-on-blur
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                      class="ltr-input"
                      label="کد پستی"
                      v-model="postalCode"
                      :rules="postalCodeRule"
                      required
                      outlined
                      dense
                      validate-on-blur
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-textarea
                      label="آدرس پستی"
                      v-model="address"
                      :rules="addressRule"
                      required
                      outlined
                      rows="3"
                      no-resize
                      dense
                      counter="200"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-subheader>
                <v-spacer></v-spacer>
                مشخصات صاحب کسب و کار
                <v-spacer></v-spacer>
              </v-subheader>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                      class="ltr-input"
                      v-model="nationalCode"
                      :rules="nationalCodeRule"
                      label="کد ملی"
                      required
                      outlined
                      dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <date-combo
                    v-model="birthdate"
                    label="تاریخ تولد"
                    :rules="birthdateRule"
                    @valid="birthdateValid = true"
                    @error="birthdateValid = false"
                    dense
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-text-field
                      class="ltr-input"
                      label="شماره شبا"
                      v-model="iban"
                      :rules="ibanRule"
                      :messages="['شبا چیست؟']"
                      suffix="IR"
                      required
                      outlined
                      dense>
                    <template v-slot:message="data">
                      <div class="d-flex justify-space-between">
                        <span class="red--text">{{ ibanError }}</span>
                        <a href="https://bahamta.com/blog/16/" target="_blank">
                          {{ data.message }}
                        </a>
                      </div>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-text-field
                      class="ltr-input"
                      label="ایمیل"
                      v-model="email"
                      :rules="emailRule"
                      required
                      outlined
                      dense
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-switch
                    v-model="wagePayerParty"
                    dense
                    color="red"
                    :persistent-hint="true"
                    :hint="hint"
                    hide-details="auto"
                    label="کارمزد به عهده پرداخت‌کننده"
                    false-value="merchant"
                    true-value="payer"
                  ></v-switch>
                </v-col>
              </v-row>

              <v-row justify="center">
                <v-col cols="12" md="4">
                  <v-btn :disabled="!valid || !birthdateValid" depressed
                         color="success" block
                         @click="createMerchant">
                    <v-icon v-if="merchantIsCreating"
                            class="mdi mdi-loading mdi-spin"></v-icon>
                    <v-icon v-else
                            class="mdi mdi-storefront"></v-icon>
                    <span class="mx-2">ثبت</span>
                  </v-btn>
                </v-col>
                <v-col cols="12" md="4">
                  <v-btn depressed
                         color="error" block
                         @click="$emit('input', false)">
                    <v-icon class="mdi mdi-close"></v-icon>
                    <span class="mx-2">انصراف</span>
                  </v-btn>
                </v-col>
              </v-row>

            </v-container>
          </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>

    <dialog-merchant-key
        v-model="keyDialog"
        :title="createdMerchant.title"
        :api_key="createdMerchant.api_key"
    />

    <v-snackbar v-model="snackbar" :timeout="5000" top left
                class="justify-center white--text" color="error">
      {{ serverError }}
      <v-btn text @click="snackbar = false">باشه</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
  import {toJalaali, isValidJalaaliDate} from 'jalaali-js'
  import {mapGetters} from 'vuex'
  import {
    onlyDigits,
    onlyDigitsAndDash,
    toEnglishDigits,
    toPersianDigits
  } from './base/number'
  import {
    isNotEmptyRule,
    limitedTo,
    onlyPersianArabicRule,
    minimumWithoutWithespace,
  } from './base/input'
  import {validIbanRule} from './base/iban'
  import {validEmailRule} from './base/email'
  import {validWebsiteRule} from './base/website'
  import {validPhoneRule} from './base/phone'
  import {validPostalCodeRule} from './base/postal_code'
  import {validNationalCodeRule} from './base/national_code'
  import DialogMerchantKey from './DialogMerchantKey'
  import DateCombo from './base/DateCombo'

  export default {
    name: "DialogMerchantNew",
    components: {
      DialogMerchantKey,
      DateCombo,
    },
    props: [
      'mode',
      'value',
      'default_merchant_id',
      'default_title',
      'default_website',
      'default_phone',
      'default_postalCode',
      'default_address',
      'default_nationalCode',
      'default_birthdate',
      'default_iban',
      'default_email',
      'default_wage_payer_party',
    ],
    data: function () {
      return {
        valid: true,
        title: '',
        titleRule: [isNotEmptyRule, onlyPersianArabicRule],
        website: '',
        websiteRule: [isNotEmptyRule, validWebsiteRule],
        phone: '',
        phoneRule: [isNotEmptyRule, validPhoneRule],
        postalCode: '',
        postalCodeRule: [isNotEmptyRule, validPostalCodeRule],
        address: '',
        addressRule: [
          isNotEmptyRule,
          minimumWithoutWithespace(15, 'آدرس کوتاه است'),
          limitedTo(200),
        ],
        nationalCode: '',
        nationalCodeRule: [isNotEmptyRule, validNationalCodeRule],
        birthdate: '',
        birthdateRule: [isNotEmptyRule, this.validDate, this.atLeast18YearsOld],
        birthdateValid: false,
        iban: '',
        ibanRule: [isNotEmptyRule, validIbanRule],
        ibanError: '',
        email: '',
        emailRule: [isNotEmptyRule, validEmailRule],
        keyDialog: false,
        snackbar: false,
        serverError: '',
        wagePayerParty: 'merchant',
      }
    },
    watch: {
      phone(v) {
        this.phone = toPersianDigits(onlyDigitsAndDash(v))
      },
      postalCode(v) {
        this.postalCode = toPersianDigits(onlyDigitsAndDash(v))
      },
      nationalCode(v) {
        this.nationalCode = toPersianDigits(onlyDigits(v))
      },
      iban(v) {
        this.iban = toPersianDigits(onlyDigits(v))
        this.ibanError = this.validIban(this.iban)
      },
      keyDialog(show) {
        if (show) {
          this.$router.push({query: {merchant_created: null}})
        } else {
          this.$router.push({query: {merchant_created: undefined}})
        }
      }
    },
    computed: {
      ...mapGetters('profile', {
        profileIsLoading: 'isLoading',
        profileNationalcode: 'nationalcode',
        profileBirthdate: 'birthdate',
        profileIban: 'iban',
        profileEmail: 'email',
      }),
      ...mapGetters('merchant', {
        merchantIsCreating: 'isCreating',
        createdMerchant: 'created',
      }),
      headline() {
        return this.mode == 'edit' ? 'ویرایش فروشگاه' : 'فروشگاه جدید'
      },
      wageByPayer() {
        return this.wagePayerParty == 'payer'
      },
      hint() {
        return this.wageByPayer ? 'مبلغ کارمزد به مبلغ اصلی اضافه شده و پرداخت کننده باید مجموع آن را بپردازد.' : ''
      },
      eighteenYearsAgo() {
        let now = new Date()
        let eighteenYearsAgo = new Date(
          now.setFullYear(now.getFullYear() - 18))

        let j = toJalaali(
          eighteenYearsAgo.getFullYear(),
          eighteenYearsAgo.getMonth()+1,
          eighteenYearsAgo.getDate())

        return j.jy + '/' +
         (j.jm + '').padStart(2, '0') + '/' + 
         (j.jd + '').padStart(2, '0')
      },
    },
    methods: {
      validDate(d) {
        let p = toEnglishDigits(d).split('/')
        return isValidJalaaliDate(+p[0], +p[1], +p[2]) ||
          'تاریخ تولد معتبر نیست'
      },
      atLeast18YearsOld(d) {
        return d < this.eighteenYearsAgo || 'سن شما زیر ۱۸ سال است'
      },
      validIban(iban) {
        let e = validIbanRule(iban)
        if (e === true) return ''
        return e
      },
      loadProfile() {
        if (this.default_merchant_id) {
          this.title = this.default_title
          this.website = this.default_website
          this.phone = this.default_phone
          this.postalCode = this.default_postalCode
          this.address = this.default_address
          this.nationalCode = this.default_nationalCode
          this.birthdate = this.default_birthdate
          this.iban = this.default_iban
          this.email = this.default_email
          this.wagePayerParty = this.default_wage_payer_party
          return
        }

        this.nationalCode = this.profileNationalcode
        this.birthdate = this.profileBirthdate
        this.iban = this.profileIban
        this.email = this.profileEmail
      },
      createMerchant() {
        const params = {
          title: this.title,
          category: 4816,
          subcategory: 0,
          iban: 'IR' + toEnglishDigits(this.iban),
          birthdate: this.birthdate,
          nid: toEnglishDigits(this.nationalCode),
          type: 'individual',
          phone: toEnglishDigits(this.phone),
          address: this.address.trim(),
          postal_code: toEnglishDigits(this.postalCode),
          email: this.email.trim(),
          website: this.website.trim(),
          wage_payer_party: this.wagePayerParty
        }

        if (this.default_merchant_id) {
          params.merchant_id = this.default_merchant_id

          this.$store.dispatch('merchant/update', params)
            .then(() => {
              this.$emit('input', false)
            })
            .catch(this.catch)
          return
        }

        this.$store.dispatch('merchant/create', params)
          .then(() => {
            this.$emit('input', false)
            this.keyDialog = true
          })
          .catch(this.catch)
      },
      catch(e) {
        switch (e) {
          case 'INVALID_NID':
            this.serverError = 'کد ملی معتبر نیست'
            break
          case 'INVALID_BIRTHDATE':
            this.serverError = 'تاریخ تولد معتبر نیست'
            break
          case 'INVALID_IBAN':
            this.serverError = 'شبا معتبر نیست'
            break
          case 'INVALID_EMAIL':
            this.serverError = 'ایمیل معتبر نیست'
            break
          case 'INVALID_PHONE':
            this.serverError = 'تلفن معتبر نیست'
            break
          case 'INVALID_ADDRESS':
            this.serverError = 'آدرس معتبر نیست'
            break
          case 'INVALID_POSTAL_CODE':
            this.serverError = 'کدپستی معتبر نیست'
            break
          case 'INVALID_WEB_SITE':
            this.serverError = 'سایت معتبر نیست'
            break
          default:
            this.serverError = e
        }
        this.snackbar = true
      },
    },
    created() {
      this.$store.subscribe(mutation => {
        switch (mutation.type) {
          case 'profile/set':
          case 'profile/update':
            this.loadProfile()
            break
        }
      })

      this.loadProfile()
    },
  }
</script>

<style scoped>

</style>