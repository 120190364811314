import {toEnglishDigits} from "./number";

const phoneRegex = /^0[1-8][1-9]{1,2}-[0-9]{4,8}$/

function validPhone(number) {
  return phoneRegex.test(toEnglishDigits(number))
}

function validPhoneRule(number) {
  if (!number.includes('-')) return 'کد شهر را با خط تیره جدا کنید'
  if (validPhone(number))
    return true
  return 'شماره تلفن معتبر نیست'
}

export {
  validPhone,
  validPhoneRule,
}