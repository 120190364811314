<template>
  <v-card>
    <router-link
      :to="{name: 'merchant_detail', params: {id: merchantId}}">
      <v-card-title @click="setCurrentMerchantId">
        <v-icon class="mx-2 mdi mdi-storefront"></v-icon>
        <span>{{ merchant.merchant.title }}</span>
        <v-btn v-if="terminalState(merchant.terminals) !== 'registered'"
               text small rounded :color="merchantTerminalIconColor(merchant)"
        >{{ merchantTerminalIconTooltip(merchant) }}
        </v-btn>
      </v-card-title>
    </router-link>
  </v-card>
</template>

<script>
  import {mapGetters} from 'vuex'

  export default {
    name: "PanelMerchantSummary",
    props: ['merchantId'],
    data: () => ({
      merchant: null,
    }),
    computed: {
      ...mapGetters('merchant', {
        getMerchant: 'merchant',
      }),
    },
    methods: {
      merchantTerminalIconColor(m) {
        const state = this.terminalState(m.terminals)
        switch (state) {
          case 'no-terminal':
            return 'grey darken-1'
          case 'requested':
            return 'orange darken-1'
          case 'rejected':
            return 'red darken-1'
          case 'registered':
            return 'green darken-1'
        }
      },
      merchantTerminalIconTooltip(m) {
        const state = this.terminalState(m.terminals)
        switch (state) {
          case 'no-terminal':
            return 'درگاه: وجود ندارد'
          case 'requested':
            return 'درگاه: در حال بررسی'
          case 'rejected':
            return 'درگاه: رد شده'
          case 'registered':
            return 'درگاه: تایید شده'
        }
      },
      setCurrentMerchantId(){
        this.$store.commit('setCurrentMerchantId', this.merchant.merchant.merchant_id)
      },
    },
    created() {
      this.$store.subscribe(mutation => {
        if (mutation.type === 'merchant/set') {
          this.merchant = this.getMerchant(this.merchantId)
        }
      })

      this.merchant = this.getMerchant(this.merchantId)
    },
  }
</script>

<style scoped>

</style>