// import { validate as uuidValidate } from 'uuid';
// const Uuid = require('uuid');
const Uuid = require('uuid');

function withdrawState(state) {
  var result = ""
  switch (state) {
    case "new":
    case "ongoing":
    case "sent":
    case "in-progress":
      result = "در حال پردازش"
      break;
    case "done":
      result = "انجام شده"
      break;
    case "error":
      result = "خطا"
      break;
    default:
      result = "نامشخص"
      break;
  }
  return result
}

function withdrawStateColor(state) {
  switch (state) {
    case "error":
      return 'red'
    default:
      return ''
  }
}

function validAddress(address) {
  return Uuid.validate(address);
}

function validAddressRule(address) {
  if (validAddress(address))
    return true
  return 'آدرس وارد شده معتبر نیست'
}

export {
  withdrawState,
  withdrawStateColor,
  validAddress,
  validAddressRule,
}
