const emptyRegex = /^$/
function isEmpty(msg) {
  return emptyRegex.test(msg)
}

function isNotEmptyRule(msg) {
  if (!isEmpty(msg))
    return true
  return 'حتما باید پر شود'
}

function minimum(count, error) {
  return function (message) {
    if (message.length < count)
      return error
    return true
  }
}

function minimumWithoutWithespace(count, error) {
  return function (message) {
    message = message.replace(/\s/g, '');
    if (message.length < count)
      return error
    return true
  }
}

let persianRegex = /^[\u0600-\u06FF\u200c ]*$/u
function onlyPersianArabic(msg) {
  return persianRegex.test(msg)
}

function onlyPersianArabicRule(msg) {
  if (onlyPersianArabic(msg))
    return true
  return 'تنها حروف فارسی و عربی مجاز است'
}

function limitedTo(limit) {
  return function (message) {
    if (message.length <= limit)
      return true
    return 'خارج از محدوده تعداد کاراکتر مجاز'
  }
}

function removeSpace(value) {
  return ('' + (value || '')).replace(/\s/g, '')
}

export {
  isEmpty,
  isNotEmptyRule,
  minimum,
  minimumWithoutWithespace,
  onlyPersianArabic,
  onlyPersianArabicRule,
  limitedTo,
  removeSpace,
}
