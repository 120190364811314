<template>
  <div class="date-combo">
    <div class="date-container" :class="{ errored: error, focused, dense }">
      <input
        @focus="focused = true"
        @blur="focused = false"
        @input="changeYear"
        v-model="year"
        placeholder="سال"
      />
      <span class="separator">/</span>
      <input
        @focus="focused = true"
        @blur="focused = false"
        @input="changeMonth"
        v-model="month"
        placeholder="ماه"
      />
      <span class="separator">/</span>
      <input
        @focus="focused = true"
        @blur="focused = false"
        @input="changeDay"
        v-model="day"
        placeholder="روز"
      />
    </div>

    <label :class="{ errored: error, focused }">{{ label }}</label>

    <div class="date-details" :class="{ errored: error }">{{ msg }}</div>
  </div>
</template>

<script>
export default {
  name: "date-combo",
  props: {
    value: String,
    label: String,
    rules: {
      type: Array,
      validator: function(value) {
        if (!Array.isArray(value)) return false;

        for (let i = 0; i < value.length; i++)
          if (typeof value[i] !== "function") return false;

        return true;
      },
    },
    dense: Boolean,
  },
  data: function() {
    const p = this.value.split("/");
    const year = +this.toEnglishDigits(this.onlyDigits(p[0]));
    const month = +this.toEnglishDigits(this.onlyDigits(p[1]));
    const day = +this.toEnglishDigits(this.onlyDigits(p[2]));

    const d = {
      focused: false,
      year: "",
      month: "",
      day: "",
      error: false,
      msg: "",
    };

    if (p.length === 3) {
      d.year = this.toPersianDigits(year);
      d.month = this.toPersianDigits(month);
      d.day = this.toPersianDigits(day);

      const e = this.runRules(this.rules, d.year, d.month, d.day);
      if (e !== true) {
        d.msg = e;
        d.error = true;
        this.$emit("error");
      } else {
        d.msg = "";
        d.error = false;
        this.$emit("valid");
      }
    }

    return d;
  },
  methods: {
    changeYear() {
      this.year = this.toPersianDigits(this.onlyDigits(this.year));
      this.$emit("input", this.format(this.year, this.month, this.day));
      this.check(this.runRules(this.rules, this.year, this.month, this.day));
    },
    changeMonth() {
      this.month = this.toPersianDigits(this.onlyDigits(this.month));
      this.$emit("input", this.format(this.year, this.month, this.day));
      this.check(this.runRules(this.rules, this.year, this.month, this.day));
    },
    changeDay() {
      this.day = this.toPersianDigits(this.onlyDigits(this.day));
      this.$emit("input", this.format(this.year, this.month, this.day));
      this.check(this.runRules(this.rules, this.year, this.month, this.day));
    },
    format(year, month, day) {
      return this.toEnglishDigits(
        year +
          "/" +
          (month + "").padStart(2, "0") +
          "/" +
          (day + "").padStart(2, "0")
      );
    },
    runRules(rules, year, month, day) {
      const d = this.format(year, month, day);
      for (let i = 0; i < rules.length; i++) {
        const v = rules[i](d);
        if (v !== true) {
          return v;
        }
      }
      return true;
    },
    check(e) {
      if (e !== true) {
        this.msg = e;
        this.error = true;
        this.$emit("error");
        return;
      }
      this.msg = "";
      this.error = false;
      this.$emit("valid");
    },
  },
};
</script>

<style scoped>
.date-combo {
  position: relative;
}

.date-combo .date-container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
  direction: ltr;
  width: 100%;
  height: 56px;
  border: 1px rgba(0, 0, 0, 0.38) solid;
  padding: 1px;
  border-radius: 4px;
  margin: 0 0 8px 0;
}

.date-combo .date-container.dense {
  height: 40px;
}

.date-combo .date-container:hover {
  border-color: rgba(0, 0, 0, 0.87);
}

.date-combo .date-container.focused {
  padding: 0px;
  border: 2px var(--v-primary-base) solid;
}

.date-combo .date-container.errored {
  padding: 0px;
  border: 2px var(--v-error-base) solid;
}

.date-combo label,
.date-combo .date-container input {
  font-family: "Vazir", "Georgia";
}

.date-combo label {
  position: absolute;
  color: rgba(0, 0, 0, 0.6);
  background-color: white;
  direction: rtl;
  padding: 0 2px;
  top: -9px;
  right: 8px;
  font-size: 12.8px;
  line-height: 20px;
}

.date-combo .date-container .separator {
  color: rgba(0, 0, 0, 0.38);
}

.date-combo .date-container .separator,
.date-combo .date-container input {
  height: 32px;
  padding: 8px 0px;
}

.date-combo .date-container:hover .separator {
  color: rgba(0, 0, 0, 0.87);
}

.date-combo .date-container.focused .separator,
.date-combo label.focused {
  color: var(--v-primary-base);
}

.date-combo .date-container.errored .separator,
.date-combo label.errored {
  color: var(--v-error-base);
}

.date-combo .date-container input {
  direction: ltr;
  text-align: center;
  width: 100%;
  caret-color: var(--v-primary-base);
}

.date-combo .date-container input:focus {
  outline: none;
}

.date-combo .date-details {
  margin: 0 0 8px 0;
  padding: 0 12px;
  height: 14px;
  font-size: 12px;
  line-height: 18px;
}

.date-combo .date-details.errored {
  color: var(--v-error-base);
}
</style>
