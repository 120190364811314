const websiteRegex = /^(http(s)?:\/\/)?\w+(-\w+)?(\.\w+(-\w+)?)+\/?$/

function validWebsite(address) {
  return websiteRegex.test(address)
}

function validWebsiteRule(address) {
  if (validWebsite(address))
    return true
  return 'آدرس وارد شده معتبر نیست'
}

export {
  validWebsite,
  validWebsiteRule,
}