import { toEnglishDigits } from './number';
import { toGregorian, isValidJalaaliDate } from 'jalaali-js';

const dateTimePattern = /^(\d{4})-(\d{1,2})-(\d{1,2})( (\d{1,2}):(\d{1,2}):(\d{1,2}))?$/;

function parseJalaaliDateTimeString(text, upperBoundInclusive) {
  if (text === '' || text === null) {
    return 0;
  }
  text = toEnglishDigits(text).replace(/\//g, "-");
  const match = text.match(dateTimePattern);
  if (match === null) {
    throw new Error("Not a valid datetime");
  }
  const jYear = parseInt(match[1]);
  const jMonth = parseInt(match[2]);
  const jDay = parseInt(match[3]);
  const jHour = parseInt(match[5]) || 0;
  const jMinute = parseInt(match[6]) || 0;
  const jSecond = parseInt(match[7]) || 0;

  if (!isValidJalaaliDate(jYear, jMonth, jDay)) {
    throw new Error("Not a valid datetime");
  }
  const gDate = toGregorian(jYear, jMonth, jDay);

  const date = new Date(gDate.gy, gDate.gm - 1, gDate.gd,
                        jHour, jMinute, jSecond);
  if (!jHour && !jMinute && !jSecond && upperBoundInclusive) {
    date.setDate(date.getDate() + 1);
  }
  return Math.floor(date.getTime() / 1000);
}

export {
  parseJalaaliDateTimeString,
}
