import {toEnglishDigits} from "./number"

const nationalCodePattern = /^[0-9]{10}$/
function validNationalCode(code) {
  code = toEnglishDigits(code)
  if (!nationalCodePattern.test(code)) {
    return false
  }

  // validate using http://www.aliarash.com/article/codemeli/codemeli.htm
  let total = 0
  for (let i = 0; i < 9; i++) {
    total += parseInt(code[i], 10) * (10 - i)
  }

  let rem = total % 11
  return (
    ((rem < 2) && (parseInt(code[9], 10) == rem)) ||
    ((rem >= 2) && (parseInt(code[9], 10) == (11 - rem)))
  )
}

function validNationalCodeRule(code) {
  if (validNationalCode(code))
    return true
  return 'کد ملی معتبر نیست'
}

export {
  validNationalCode,
  validNationalCodeRule,
}