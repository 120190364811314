import {toEnglishDigits, bigMod} from "./number";
import {removeSpace} from "./input";

const banks = {
  '010': 'بانک مرکزی',
  '011': 'بانک صنعت و معدن',
  '012': 'بانک ملت',
  '013': 'بانک رفاه کارگران',
  '014': 'بانک مسکن',
  '015': 'بانک سپه',
  '016': 'بانک کشاورزی',
  '017': 'بانک ملی',
  '018': 'بانک تجارت',
  '019': 'بانک صادرات',
  '020': 'بانک توسعه صادرات',
  '021': 'بانک پست بانک',
  '022': 'بانک توسعه تعاون',
  '051': 'موسسه اعتباری توسعه',
  '052': 'بانک قوامین',
  '053': 'بانک کار آفرین',
  '054': 'بانک پارسیان',
  '055': 'بانک اقتصاد نوین',
  '056': 'بانک سامان',
  '057': 'بانک پاسارگاد',
  '058': 'بانک سرمایه',
  '059': 'بانک سینا',
  '060': 'قرض الحسنه مهر',
  '061': 'بانک شهر',
  '062': 'بانک آینده',
  '063': 'بانک انصار',
  '064': 'بانک گردشگری',
  '065': 'بانک حکمت ایرانیان',
  '066': 'بانک دی',
  '069': 'بانک ایران زمین',
  '070': 'بانک قرض الحسنه رسالت',
  '073': 'بانک سپه', // this was used to be called موسسه مالی و اعتباری کوثر
  '075': 'موسسه مالی و اعتباری ملل',
  '078': 'بانک خاور میانه',
  '080': 'موسسه اعتباری نور',
}

const ibanPattern = /^IR[0-9]{24}$/
const ibanOnlyNumberPattern = /^[0-9]{24}$/

function cleanIban(iban) {
  iban = toEnglishDigits(removeSpace(iban)).toUpperCase()

  if (ibanOnlyNumberPattern.test(iban))
    return 'IR' + iban

  if (ibanPattern.test(iban))
    return iban

  return null
}

function bankCode(iban) {
  iban = cleanIban(iban)
  if (iban == null)
    return null

  return iban.substr(4, 3)
}

function bankName(iban) {
  const code = bankCode(iban)
  if (code == null)
    return ''

  return banks[code]
}

function bankLogo(iban) {
  const code = bankCode(iban)
  if (code == null)
    return ''

  return '/img/bank-logos/' + code + '.jpg'
}

function validIban(iban) {
  iban = cleanIban(iban)

  if (bankName(iban) === '')
    return false

  const bn = iban.substr(4) + iban.substr(0, 4).replace('IR', '1827')
  return bigMod(bn, 97) === 1
}

function validIbanRule(iban) {
  if (validIban(iban))
    return true
  return 'شبا وارد شده معتبر نیست'
}

export {
  cleanIban,
  bankCode,
  bankName,
  bankLogo,
  validIban,
  validIbanRule,
}