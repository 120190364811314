<template>
  <v-dialog
      :value="value"
      @input="$emit('input', $event)"
      max-width="600px">

    <v-card>
      <v-card-title class="title">
        حذف فروشگاه
      </v-card-title>

      <v-card-text>
        اطلاعات، گزارشات و کلید فروشگاه حذف خواهد شد و به هیچ عنوان امکان دسترسی
        به آنها وجود ندارد. (پرداخت‌های قبلی، طبق روال با شما تسویه می‌شود)
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="success darken-1"
            text
            @click="removeMerchant()">
          <v-icon v-if="loading"
                  class="mdi mdi-loading mdi-spin"></v-icon>
          حذف شود
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
            color="error darken-1"
            text
            @click="$emit('input', false)">
          پشیمان شدم
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

  export default {
    name: "DialogMerchantDelete",
    props: [
      'value',
      'merchant',
    ],
    data: () => ({
      loading: false,
    }),
    methods: {
      removeMerchant() {
        this.loading = true
        this.$store.dispatch('merchant/remove', {
          'merchant_id': this.merchant,
        }).then(() => {
          this.loading = false
          this.$emit('input', false)
          this.$router.replace({name: 'panel_merchants'})
        })
      },
    },
  }
</script>

<style scoped>

</style>