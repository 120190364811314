// const persianDigits = "۰۱۲۳۴۵۶۷۸۹"
const persianDigits = {
  '-': '-',
  '0': '۰', '1': '۱', '2': '۲', '3': '۳', '4': '۴',
  '5': '۵', '6': '۶', '7': '۷', '8': '۸', '9': '۹',
  '٠': '۰', '١': '۱', '٢': '۲', '٣': '۳', '٤': '۴',
  '٥': '۵', '٦': '۶', '٧': '۷', '٨': '۸', '٩': '۹',
}
const englishDigits = {
  '-': '-',
  '۰': '0', '۱': '1', '۲': '2', '۳': '3', '۴': '4',
  '۵': '5', '۶': '6', '۷': '7', '۸': '8', '۹': '9',
  '٠': '0', '١': '1', '٢': '2', '٣': '3', '٤': '4',
  '٥': '5', '٦': '6', '٧': '7', '٨': '8', '٩': '9',
}

function toPersianDigits(number) {
  return (('' + (number || '')))
    .replace(/[0-9٠-٩]/g, function (x) {
      return persianDigits[x]
    })
}

function toEnglishDigits(number) {
  return ('' + (number || ''))
    .replace(/[۰-۹٠-٩]/g, function (x) {
      return englishDigits[x]
    })
}

function onlyDigits(message) {
  return ('' + (message || ''))
    .replace(/[^0-9٠-٩۰-۹]/g, '')
}

function onlyDigitsAndDash(message) {
  return ('' + (message || ''))
    .replace(/[^0-9٠-٩۰-۹-]/g, '')
}

function bigMod(num, a) {
  let rem = 0
  for (let i = 0; i < num.length; i++)
    rem = (rem * 10 + parseInt(num[i])) % a
  return rem
}

function isNumber(text) {
  return !isNaN(text) && !isNaN(parseFloat(text));
}

function toPersianDigitsIfNumber(text) {
  return isNumber(text) ? toPersianDigits(text) : text;
}



export {
  toPersianDigits,
  toPersianDigitsIfNumber,
  toEnglishDigits,
  onlyDigits,
  onlyDigitsAndDash,
  bigMod,
}
