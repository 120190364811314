import { render, staticRenderFns } from "./DialogMerchantKeyRenew.vue?vue&type=template&id=35c0be10&scoped=true&"
import script from "./DialogMerchantKeyRenew.vue?vue&type=script&lang=js&"
export * from "./DialogMerchantKeyRenew.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35c0be10",
  null
  
)

export default component.exports