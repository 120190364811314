<template>
  <v-app id="vuetify-app" class="webpay-panel">
    <v-navigation-drawer app right dense mobile-breakpoint="960"
                         v-model="navIsOpen">

      <v-list nav>
        <v-list-item to="/">
          <v-list-item-avatar class="mx-auto" size="75">
            <v-img contain src="/img/bahamta/logo-circle.svg?2"/>
          </v-list-item-avatar>
        </v-list-item>

        <v-list-item v-if="profileIsLoading"
                     :input-value="navState === 'profile'">
          <v-skeleton-loader width="100%" type="list-item-two-line">
          </v-skeleton-loader>
        </v-list-item>

        <router-link v-else :to="{name: 'profile'}">
          <v-list-item link @click="getProfile"
                       :input-value="navState === 'profile'">
            <v-list-item-content class="text-center">
              <v-list-item-title class="title">{{ profileFullname }}
              </v-list-item-title>
              <v-list-item-subtitle>{{ profileEmail }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </router-link>
      </v-list>

      <v-divider class="my-3"/>

      <v-list nav>
        <router-link :to="{name: 'panel_merchants'}">
          <v-list-item link :input-value="navState === 'merchants'">
            <v-list-item-icon>
              <v-icon class="mdi mdi-storefront"></v-icon>
            </v-list-item-icon>
            <v-list-item-title>فروشگاه‌ها</v-list-item-title>
          </v-list-item>
        </router-link>
        <router-link :to="{name: 'settlement'}">
          <v-list-item link :input-value="navState === 'settlement'">
            <v-list-item-icon>
              <v-icon class="mdi mdi-file-chart-outline"></v-icon>
            </v-list-item-icon>
            <v-list-item-title>گزارش تسویه</v-list-item-title>
          </v-list-item>
        </router-link>
        <!-- <router-link :to="{name: 'affiliation'}">
          <v-list-item link :input-value="navState === 'affiliation'">
            <v-list-item-icon>
              <v-icon class="mdi mdi-currency-usd"></v-icon>
            </v-list-item-icon>
            <v-list-item-title>کسب درآمد</v-list-item-title>
          </v-list-item>
        </router-link> -->
      </v-list>

      <template v-slot:append>
        <v-list nav dense>
          <router-link :to="{name: 'plugins'}">
            <v-list-item link>
              <v-list-item-icon>
                <v-icon class="mdi mdi-toy-brick-outline"></v-icon>
              </v-list-item-icon>
              <v-list-item-title>افزونه‌ها</v-list-item-title>
            </v-list-item>
          </router-link>
          <router-link to="/doc/api">
            <v-list-item link>
              <v-list-item-icon>
                <v-icon class="mdi mdi-file-code-outline"></v-icon>
              </v-list-item-icon>
              <v-list-item-title>مستندات</v-list-item-title>
            </v-list-item>
          </router-link>
          <router-link :to="{name: 'support'}">
            <v-list-item link :input-value="navState === 'support'">
              <v-list-item-icon>
                <v-icon class="mdi mdi-face-agent"></v-icon>
              </v-list-item-icon>
              <v-list-item-title>پشتیبانی</v-list-item-title>
            </v-list-item>
          </router-link>
        </v-list>

        <v-footer inset>
          <v-row>
            <v-col class="text-center" cols="12">
              <strong>باهمتا</strong>
            </v-col>
          </v-row>
        </v-footer>
      </template>

    </v-navigation-drawer>

    <v-main class="grey lighten-5">
      <router-view
          @toggleNavBar="navIsOpen = !navIsOpen"
          @navState="updateNavState">
      </router-view>
    </v-main>

    <v-snackbar
        v-model="showCopiedSnackbar"
        :timeout="3000" top left
        class="justify-center">
      کپی شد.
      <v-btn text @click="showCopiedSnackbar = false" color="primary">
        باشه
      </v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
  import {mapActions, mapGetters} from 'vuex'

  export default {
    name: "panel",
    data: function () {
      return {
        navIsOpen: this.largerThanMd,
        navState: '',
        showCopiedSnackbar: false,
      }
    },
    computed: {
      ...mapGetters({
        noProfile: 'profile/noProfile',
        profileIsLoading: 'profile/isLoading',
        profileFullname: 'profile/fullname',
        profileEmail: 'profile/email',
      }),
      largerThanMd() {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs':
            return false
          case 'sm':
            return false
        }
        return true
      },
    },
    methods: {
      ...mapActions({
        getProfile: 'profile/load',
        loadMerchants: 'merchant/load',
      }),
      updateNavState(state) {
        this.navState = state
      },
    },
    watch: {
      $route() {
        if (this.noProfile &&
          this.$router.currentRoute.name !== 'profile') {
          this.$router.replace({name: 'profile'})
        }
      }
    },
    created() {
      this.$store.subscribe(mutation => {
        switch (mutation.type) {
          case 'showCopiedSnackbar':
            this.showCopiedSnackbar = true
            break
        }

        switch (mutation.payload) {
          case 'not_authorized':
            this.$router.replace({name: 'login'})
            break
          case 'no_profile':
            if (this.$router.currentRoute.name !== 'profile')
              this.$router.replace({name: 'profile'})
            break
        }
      })

      if (!this.$store.getters.loggedIn) {
        this.$router.replace({name: 'login'})
        return
      }
      this.getProfile()
      this.loadMerchants()
    },
  }
</script>

<style lang="scss">
  @import '@/scss/variables.scss';

  a {
    text-decoration: none;
    color: #49B4AF;
  }

  .webpay-panel .centered-input input {
    text-align: center;
  }

  .webpay-panel .left-input input {
    text-align: left;
  }

  .webpay-panel .ltr-input input {
    direction: ltr;
  }

  .webpay-panel .ltr {
    direction: ltr;
  }

  .webpay-panel .address input {
    font-family: 'Courier New', Courier, monospace;
  }
</style>
