<template>
  <v-container>

    <v-app-bar app color="white">
      <v-row align="center">
        <v-col cols="2">
          <v-app-bar-nav-icon
              class="d-flex d-md-none"
              @click="$emit('toggleNavBar')"/>
        </v-col>

        <v-col cols="8">
          <v-toolbar-title class="text-center">
            گزارش تسویه
          </v-toolbar-title>
        </v-col>

        <v-col cols="2">
        </v-col>
      </v-row>
    </v-app-bar>

    <v-container>
      <v-card>
        <v-card-text>
          <v-data-table
              :headers="headers"
              :items="transfers"
              :loading="loading"
              :server-items-length="count"
              :items-per-page="limit"
              @update:items-per-page="updateLimit"
              @update:page="updatePage"
              :footer-props="{'items-per-page-options': [5, 10, 50]}"
              disable-sort>

            <template v-slot:item.date="{ item }">
              <div style="direction: ltr">
                {{ formatDate(item.date) }}
              </div>
            </template>

            <template v-slot:item.iban="{ item }">
              <div style="direction: ltr">
                <code>{{ formatIban(item.iban) || '-' }}</code>
              </div>
            </template>

            <template v-slot:item.transferred="{ item }">
              <div style="direction: ltr">
                {{ toPersianDigits(formatNumber(item.transferred)) || '۰' }}
              </div>
            </template>

            <template v-slot:item.wage="{ item }">
              <div style="direction: ltr">
                {{ toPersianDigits(formatNumber(item.wage)) || '۰' }}
              </div>
            </template>

            <template v-slot:item.count="{ item }">
              <div style="direction: ltr">
                {{ toPersianDigits(formatNumber(item.count)) || '۰' }}
              </div>
            </template>

            <template v-slot:item.transfer_trace="{ item }">
              <div style="direction: ltr">
                {{ toPersianDigits(item.transfer_trace) }}
              </div>
            </template>

          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>

  </v-container>
</template>

<script>
  import {mapGetters} from 'vuex'
  import {toPersianDigits} from '../base/number'
  import {formatNumber} from '../base/format'
  import {toJalaali} from 'jalaali-js'

  export default {
    name: "TransferDaily",
    data: () => ({
      loading: true,
      limit: 10,
      offset: 0,
      count: 0,
      headers: [
        {text: 'تاریخ تسویه', value: 'date', align: 'center'},
        {text: 'شبا', value: 'iban', align: 'center'},
        {text: 'مبلغ واریزی (ریال)', value: 'transferred', align: 'center'},
        {text: 'کارمزد کسر شده (ریال)', value: 'wage', align: 'center'},
        {text: 'تعداد تراکنش', value: 'count', align: 'center'},
        {text: 'کدرهگیری تسویه', value: 'transfer_trace', align: 'center'},
      ],
      transfers: [],
    }),
    computed: {
      ...mapGetters({
        getTransfers: 'merchant/transferSummary/get',
        profileCreateTime: 'profile/createTime',
      }),
    },
    methods: {
      toPersianDigits,
      formatNumber,
      updateLimit(limit) {
        this.limit = limit
        this.loadTransfers()
      },
      updatePage(page) {
        this.offset = (page - 1) * this.limit
        this.loadTransfers()
      },
      formatDate(date) {
        if (date == null)
          return ''
        
        const d = new Date(date)
        if (d.getFullYear() === 0)
          return ''

        const j = toJalaali(d.getFullYear(), d.getMonth() + 1, d.getDate())
        return toPersianDigits(
          j.jy + '/' + j.jm + '/' + j.jd)
      },
      formatIban(iban) {
        if (iban === '') {
          return ''
        }

        return iban.substr(0, 7) + ' ' +
          iban.substr(7, 8) + ' ' +
          iban.substr(15, 11)
      },
      loadTransfers() {
        this.loading = true
        let tag = 'transfers/daily'
        this.$store.dispatch('merchant/transferSummary/daily', {
          tag: tag,
          params: {
            since: this.profileCreateTime,
            until: new Date(),
            offset: this.offset,
            limit: this.limit,
          },
        }).then(() => {
          let transfers = this.getTransfers(tag)
          if (transfers == null) {
            return
          }

          this.count = transfers.info.full_count
          this.transfers = transfers.rows
          this.loading = false
        })
      },
    },
    created() {
      this.loadTransfers()
    },
    mounted() {
      this.$emit('navState', 'settlement')
    }
  }
</script>

<style scoped>

</style>