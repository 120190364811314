<template>
  <v-dialog
      :value="value"
      @input="$emit('input', $event)"
      @click:outside="closeDialog()"
      max-width="600px">

    <v-card>
      <v-card-title class="title">
        {{ title }}
      </v-card-title>

      <v-card-text dense>
        <v-container v-if="!isConfirming" dense>
            <v-row dense>
              <v-col>
                <v-text-field
                    class="ltr-input"
                    label="مبلغ برداشت (ریال)"
                    v-model="txt_amount"
                    required
                    outlined
                    dense
                    validate-on-blur
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col>
                <v-text-field
                    class="ltr-input"
                    label="شبا / آدرس کیف پول"
                    v-model="txt_target"
                    :rules="isValidIban ? ibanRule : addressRule"
                    required
                    outlined
                    dense
                    validate-on-blur
                    >
                  <template v-slot:message>
                    <div class="d-flex justify-space-between">
                      <span class="red--text">{{ targetError }}</span>
                    </div>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col align="center" justify="center">
                <v-progress-circular v-if="isObtainingTargetName"
                  :indeterminate="true"
                  :size="20"
                  >
                </v-progress-circular>
                <v-icon v-if="targetNameError" class="mx-2 mdi mdi-refresh" @click="obtainTargetName(target)"></v-icon>
                <v-avatar v-if="isValidIban && targetName !== '' && !targetNameError"
                  height="32"
                  width="32"
                  rounded
                  >
                  <img :src="bankLogo">
                </v-avatar>
                <span :class="targetNameColor + '--text'"> {{ targetName }} </span>
              </v-col>
            </v-row>

            <v-row id="action-row" dense>
              <v-spacer/>

              <v-col>
                <v-btn
                    color="success darken-1"
                    :disabled="!readyToApply"
                    @click="action()">
                  <v-icon v-if="loading"
                          class="mdi mdi-loading mdi-spin"></v-icon>
                  {{ actionLabel }}
                </v-btn>
              </v-col>

              <v-spacer/>

              <v-col>
                <v-btn
                    color="error darken-1"
                    @click="cancel()">
                  {{ cancelLabel }}
                </v-btn>
              </v-col>

              <v-spacer/>
            </v-row>
        </v-container>

        <v-container v-else dense>
          <v-row justify="center">
            <v-col cols="10">
              <v-text-field
                class="ltr-input"
                label="مبلغ برداشت (ریال)"
                :value="txt_amount"
                disabled
                readonly
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="10">
              <v-text-field
                class="ltr-input"
                label="شبا / آدرس کیف پول مقصد"
                :value="toEnglishDigits(target)"
                disabled
                readonly
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="10">
              <v-text-field
                class="ltr-input"
                label="نام صاحب حساب"
                :value="toPersianDigits(targetName)"
                disabled
                readonly
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="confirmErrorMessage != ''" justify="center">
            <span style="color : red"> {{ confirmErrorMessage }} </span>
          </v-row>
          <v-row>
            <v-spacer></v-spacer>
            <v-btn
                color="success darken-1"
                :disabled="!readyToApply"
                @click="action()">
              <v-icon v-if="loading"
                      class="mdi mdi-loading mdi-spin"></v-icon>
              {{ actionLabel }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
                color="error darken-1"
                @click="cancel()">
              {{ cancelLabel }}
            </v-btn>
            <v-spacer></v-spacer>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

  import {mapGetters} from 'vuex'
  import {validIbanRule, validIban, bankName, bankLogo, cleanIban} from './base/iban'
  import {validAddressRule, validAddress} from './base/wallet'
  import {isNotEmptyRule} from './base/input'
  import {formatNumber} from './base/format'
  import {
      onlyDigits,
      toPersianDigits,
      toEnglishDigits,
    } from './base/number'
    
  export default {
    name: "DialogWalletTnx",
    props: [
      'value',
      'merchant',
      'reference',
    ],
    data: () => ({
      loading: false,
      txt_target: '',
      txt_amount: '',
      targetName: '',
      targetNameError: false,
      isObtainingTargetName: false,
      isConfirming: false,
      ibanRule: [isNotEmptyRule, validIbanRule],
      addressRule: [isNotEmptyRule, validAddressRule],
      confirmErrorMessage: '',
    }),

    computed: {
      ...mapGetters({
        withdrawResults: 'merchant/withdrawResults',
        sendResults: 'merchant/sendResults',
        obtainedAccountOwner: 'service/accountOwner',
        obtainedWalletName: 'service/walletName',
      }),

      targetType() {
        if (validIbanRule(this.target) === true) {
          return 'iban'
        }
        
        if (validAddressRule(this.target) === true) {
          return 'wallet'
        }

        return ''
      },

      targetError() {
        let e = validIbanRule(this.target)
        if (e === true) return ''
        
        e = validAddressRule(this.target)
        if (e === true) return ''

        return 'شبا یا آدرس کیف پول نادرست است.'
      },

      target() {
        let iban = cleanIban(this.txt_target)
        if (validIban(iban)) {
          return iban
        }

        return this.toEnglishDigits(this.txt_target)
      },

      amount() {
        return toEnglishDigits(onlyDigits(this.txt_amount))
      },

      isValidIban() {
        return validIban(this.target)
      },
      
      isValidAddress() {
        return validAddress(this.target)
      },

      isValidTarget() {
        return this.isValidIban || this.isValidAddress
      },

      bankName() {
        return this.isValidIban ? bankName(this.target) : ''
      },

      bankLogo() {
        return this.isValidIban ? bankLogo(this.target) : ''
      },

      targetNameColor() {
        return this.targetNameError ? 'red' : ''
      },

      readyToApply() {
        let maxAmount = (this.isValidAddress) ? 10000000000 : 1000000000
        return this.isValidTarget
          && this.amount >= 10000
          && this.amount <= maxAmount
          && ! this.targetNameError
          && this.targetName !== ''
          && this.confirmErrorMessage === ''
      },

      title() {
        if (this.isConfirming) {
          return 'آیا این برداشت را تأیید می‌کنید؟'
        } else {
          return 'برداشت از کیف پول'
        }
      },

      actionLabel() {
        if (this.isConfirming) {
          return 'تأیید می‌کنم'
        } else {
          return 'ثبت درخواست'
        }
      },

      cancelLabel() {
        if (this.isConfirming) {
          return 'اشتباه شده!'
        } else {
          return 'پشیمان شدم'
        }
      },
    },

    watch: {
      txt_target(v) {
        this.txt_target = toEnglishDigits(v)
        if (!this.isObtainingTargetName) {
          if (this.isValidTarget) {
            this.isObtainingTargetName = true
            this.obtainTargetName(this.target)
          } else {
            this.targetName = ''
          }
        }
      },

      txt_amount(v) {
        this.txt_amount = toPersianDigits(formatNumber(onlyDigits(v)))
      },

      keyDialog(show) {
        if (show) {
          this.$router.push({query: {merchant_created: null}})
        } else {
          this.$router.push({query: {merchant_created: undefined}})
        }
      },
    },
    
    methods: {

      validTargetRule(target) {
        if (validIban(target) || validAddress(target))
          return true
        return 'آدرس وارد شده معتبر نیست'
      },

      obtainTargetName(target) {
        let type = this.targetType
        if (type === "iban") {
          return this.obtainAccountOwner(target)
        }
        if (type === "wallet") {
          return this.obtainWalletName(target)
        }
        
        return "مقصد نادرست"
      },

      obtainAccountOwner(iban) {
        this.$store.dispatch('service/obtainAccountOwner', {iban: iban})
        .then(() => {
          this.targetName = this.obtainedAccountOwner
          this.targetNameError = false
        }).catch(() => {
          this.targetName = 'خطا در استعلام نام صاحب حساب'
          this.targetNameError = true
        }).finally(() => {
          this.isObtainingTargetName = false
        })
      },

      obtainWalletName(address) {
        this.$store.dispatch('service/findWalletByAddress', {
          'merchant_id': this.merchant,
          'address': address,
        }).then(() => {
          this.targetName = this.obtainedWalletName
          this.targetNameError = false
        }).catch(err => {
          switch (err) {
            case 'UNKNOWN_WALLET_ADDRESS':
              this.targetName = 'کیف پولی با این آدرس وجود ندارد'
              break
            case 'NOT_AUTHORIZED':
              this.targetName = 'اجازه دسترسی ندارید'
              break
            default:
              this.targetName = 'خطا در استعلام نام کیف پول'
              break
          }

          this.targetNameError = true
        }).finally(() => {
          this.isObtainingTargetName = false
        })
      },

      action() {
        this.confirmErrorMessage = ''
        if (this.isConfirming) {
          if (this.isValidIban) {
            this.withdraw()
          } else if (this.isValidAddress) {
            this.send()
          } else {
            alert('شبای بانکی یا آدرس کیف پول نادرست وارد شده است.')
          }

        } else {
          this.confirm()
        }
      },

      cancel() {
        this.confirmErrorMessage = ''
        if (this.isConfirming) {
          // back to the input dialog
          this.isConfirming = false
        } else {
          this.closeDialog()
        }
      },

      confirm() {
        this.isConfirming = true
      },

      withdraw() {
        this.loading = true
        this.$store.dispatch('merchant/withdraw', {
          'merchant_id': this.merchant,
          'orders': this.target + ',' + this.amount + ',' + this.reference,
        }).then(() => {
          var errMsg = ''
          for (var i=0; i < this.withdrawResults.length; i++) {
            if (! this.withdrawResults[i].ok) {
              errMsg = errMsg + '\n«' + this.errorMessage(this.withdrawResults[i].error) + '»'
            }
          }

          this.loading = false
          if (errMsg != '') {
            this.confirmErrorMessage  = errMsg
          } else {
            this.closeDialog()
          }
          
        }).catch(error => {
            alert('خطا : ' + error.message)
        })
      },

      send() {
        this.loading = true
        this.$store.dispatch('merchant/send', {
          'merchant_id': this.merchant,
          'orders': this.target + ',' + this.amount +  ',' + this.reference,
        }).then(() => {
          var errMsg = ''
          for (var i=0; i < this.sendResults.length; i++) {
            if (! this.sendResults[i].ok) {
              errMsg = errMsg + '\n«' + this.errorMessage(this.sendResults[i].error) + '»'
            }
          }

          this.loading = false
          if (errMsg != '') {
            this.confirmErrorMessage  = errMsg
          } else {
            this.closeDialog()
          }
          
        }).catch(error => {
            alert('خطا : ' + error.message)
        })
      },

      errorMessage(errorKey) {
        switch (errorKey) {
          case 'NOT_ENOUGH_CREDIT':
            return 'موجودی ناکافی است'
          case 'SERVICE_ERROR':
            return 'خطای سیستمی'
          default:
            return 'خطای ناشناخته'
        }
      },

      closeDialog() {
        this.$emit('input', false)
        this.txt_target = ''
        this.txt_amount = ''
        this.isConfirming = false
        this.$emit('close')
      }
    },
  }
</script>

<style scoped>

#action-row {
  margin-top: 36px;
}

</style>