import {toPersianDigits} from '../base/number'
import {toJalaali} from 'jalaali-js'

function formatNumber(num) {
  if (num == undefined) {
    return ''
  }

  let number = num.toString()
  if (number.length <= 3) return number

  let rem = number.length % 3
  let result = number.substr(0, rem)
  for (let i = rem; i < number.length; i += 3) {
    if (i !== 0) {
      result += ','
    }
    result += number.substr(i, 3)
  }
  return result
}

function formatMoney(value) {
  if (value == undefined) {
    return ''
  }

  let isNegative = false
  if (value < 0) {
    isNegative =  true
    value = -value
  }

  let str = value.toString()
  let result = str

  if (str.length > 3) {
    let rem = str.length % 3
    result = str.substr(0, rem)
    for (let i = rem; i < str.length; i += 3) {
      if (i !== 0) {
        result += ','
      }
      result += str.substr(i, 3)
    }
  }

  if (isNegative) {
    result = '(' + result + ')'
  }

  return result
}

function formatDate(date) {
  if (date == null)
    return ''
  
  const d = new Date(date)
  if (d.getFullYear() === 0)
    return ''

  const j = toJalaali(d.getFullYear(), d.getMonth() + 1, d.getDate())
  return toPersianDigits(
    j.jy + '/' + j.jm + '/' + j.jd + ' ' +
    (d.getHours() + '').padStart(2, '0') + ':' +
    (d.getMinutes() + '').padStart(2, '0'))
}

function formatPan(pan) {
  return pan.substr(0, 4) + ' ' +
    pan.substr(4, 4) + ' ' +
    pan.substr(8, 4) + ' ' +
    pan.substr(12, 4)
}

function formatIban(iban) {
  return iban.substr(0, 4) + ' ' +
    iban.substr(4, 4) + ' ' +
    iban.substr(8, 4) + ' ' +
    iban.substr(12, 4) + ' ' +
    iban.substr(16, 4) + ' ' +
    iban.substr(20, 4) + ' ' +
    iban.substr(24, 2)
}

export {
  formatNumber,
  formatMoney,
  formatDate,
  formatPan,
  formatIban,
}