<template>
  <v-container>

    <v-app-bar app>
      <v-row align="center">
        <v-col cols="2" md="4">
          <v-app-bar-nav-icon
              class="d-flex d-md-none"
              @click="$emit('toggleNavBar')"/>
        </v-col>

        <v-col cols="8" md="4">
          <v-toolbar-title class="text-center">حساب کاربری</v-toolbar-title>
        </v-col>

        <v-col cols="2" md="4">
          <v-row justify="end" class="pa-4">
            <v-btn depressed :icon="$vuetify.breakpoint.smAndDown"
                   @click="exitDialog = true">
              <v-icon class="mdi mdi-logout"></v-icon>
              <span class="pa-2 d-none d-md-flex">خروج</span>
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-app-bar>

    <v-dialog v-model="exitDialog" max-width="250">
      <v-card>
        <v-card-title class="title">
          خروج از حساب کاربری
        </v-card-title>

        <v-card-text>
          آیا مطمئن هستید؟
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="success darken-1"
              text
              @click="logout()">
            بله
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
              color="error darken-1"
              text
              @click="exitDialog = false">
            خیر
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card class="my-12 pa-12 mx-auto" max-width="800px">
      <v-skeleton-loader v-if="profileIsLoading"
                         width="100%" type="card-heading, list-item,
      card-heading, list-item, card-heading, list-item">
      </v-skeleton-loader>

      <v-form v-else class="px-md-12 centered-input" ref="profile_form"
              v-model="valid">
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
                v-model="firstname"
                :rules="nameRules"
                label="نام"
                required
                outlined
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
                v-model="lastname"
                :rules="nameRules"
                label="نام خانوادگی"
                required
                outlined
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-text-field
                class="ltr-input"
                v-model="nationalcode"
                :rules="nationalcodeRule"
                validate-on-blur
                label="کد ملی"
                required
                outlined
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <date-combo
              v-model="birthdate"
              label="تاریخ تولد"
              :rules="birthdateRule"
              @valid="birthdateValid = true"
              @error="birthdateValid = false"
              />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-text-field
                class="ltr-input"
                v-model="iban"
                :messages="['شبا چیست؟']"
                :rules="ibanRule"
                validate-on-blur
                suffix="IR"
                label="شماره شبا"
                required
                outlined
                @focus="ibanError = ''"
                @blur="ibanError = validIban(iban)">
              <template v-slot:message="data">
                <div class="d-flex justify-space-between">
                  <span class="red--text">{{ ibanError }}</span>
                  <a href="https://bahamta.com/blog/16/" target="_blank">
                    {{ data.message }}
                  </a>
                </div>
              </template>
            </v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-text-field
                class="ltr-input"
                v-model="email"
                :rules="emailRule"
                validate-on-blur
                label="ایمیل"
                required
                outlined
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="12" md="4">
            <v-btn :disabled="!valid || !birthdateValid" depressed
                   color="success" block @click="updateProfile">
              <v-icon v-if="profileIsUpdating"
                      class="mdi mdi-loading mdi-spin"></v-icon>
              <v-icon v-else
                      class="mdi mdi-checkbox-marked-circle-outline"></v-icon>
              <span class="mx-2">ذخیره</span>
            </v-btn>
          </v-col>
          <v-col cols="12" md="4">
            <v-btn depressed color="error" block
                   :to="{name: 'panel_merchants'}">
              <v-icon class="mdi mdi-close"></v-icon>
              <span class="mx-2">انصراف</span>
            </v-btn>
          </v-col>
        </v-row>

      </v-form>
    </v-card>

    <v-snackbar v-model="snackbar" :timeout="5000" top left
                class="justify-center white--text" color="error">
      {{ serverError }}
      <v-btn text @click="snackbar = false">باشه</v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
  import {mapGetters} from 'vuex'
  import {isNotEmptyRule, onlyPersianArabicRule} from './base/input'
  import {validNationalCodeRule} from './base/national_code'
  import {validEmailRule} from './base/email'
  import {validIbanRule} from './base/iban'
  import {
    onlyDigits,
    toEnglishDigits,
    toPersianDigits
  } from './base/number'
  import {toJalaali, isValidJalaaliDate} from 'jalaali-js'
  import DateCombo from './base/DateCombo'

  export default {
    name: "panel-profile",
    components: {
      DateCombo,
    },
    data: function() { return {
      valid: true,
      firstname: '',
      lastname: '',
      nameRules: [isNotEmptyRule, onlyPersianArabicRule],
      nationalcode: '',
      nationalcodeRule: [isNotEmptyRule, validNationalCodeRule],
      birthdate: '',
      birthdateRule: [isNotEmptyRule, this.validDate, this.atLeast18YearsOld],
      birthdateValid: false,
      iban: '',
      ibanRule: [validIbanRule],
      ibanError: '',
      email: '',
      emailRule: [isNotEmptyRule, validEmailRule],
      exitDialog: false,
      snackbar: false,
      serverError: '',
    }},
    watch: {
      nationalcode: function (v) {
        this.nationalcode = toPersianDigits(onlyDigits(v))
      },
      iban: function (v) {
        this.iban = toPersianDigits(onlyDigits(v))
      },
    },
    computed: {
      ...mapGetters('profile', {
        profileIsLoading: 'isLoading',
        profileIsUpdating: 'isUpdating',
        profileFirstname: 'firstname',
        profileLastname: 'lastname',
        profileNationalcode: 'nationalcode',
        profileBirthdate: 'birthdate',
        profileIban: 'iban',
        profileEmail: 'email',
      }),
      eighteenYearsAgo() {
        let now = new Date()
        let eighteenYearsAgo = new Date(
          now.setFullYear(now.getFullYear() - 18))

        let j = toJalaali(
          eighteenYearsAgo.getFullYear(),
          eighteenYearsAgo.getMonth() + 1,
          eighteenYearsAgo.getDate())

        return j.jy + '/' +
         (j.jm + '').padStart(2, '0') + '/' + 
         (j.jd + '').padStart(2, '0')
      },
    },
    methods: {
      validDate(d) {
        let p = toEnglishDigits(d).split('/')
        return isValidJalaaliDate(+p[0], +p[1], +p[2]) ||
          'تاریخ تولد معتبر نیست'
      },
      atLeast18YearsOld(d) {
        return d < this.eighteenYearsAgo || 'سن شما زیر ۱۸ سال است'
      },
      validIban(iban) {
        let e = validIbanRule(iban)
        if (e === true) return ''
        return e
      },
      loadProfile() {
        this.firstname = this.profileFirstname
        this.lastname = this.profileLastname
        this.nationalcode = this.profileNationalcode
        this.birthdate = this.profileBirthdate
        this.iban = this.profileIban
        this.email = this.profileEmail
      },
      changed() {
        return (
          this.firstname !== this.profileFirstname ||
          this.lastname !== this.profileLastname ||
          toEnglishDigits(this.nationalcode) !== toEnglishDigits(this.profileNationalcode) ||
          this.birthdate !== this.profileBirthdate ||
          toEnglishDigits('IR' + this.iban) !== toEnglishDigits(this.profileIban) ||
          this.email !== this.profileEmail
        )
      },
      updateProfile() {
        if (this.changed()) {
          const params = {
            first_name: this.firstname,
            last_name: this.lastname,
            email: this.email,
            nid: toEnglishDigits(this.nationalcode),
            birthdate: this.birthdate,
            iban: toEnglishDigits('IR' + this.iban),
            referral_code: this.$store.state.referralCode
          }

          this.$store.dispatch('profile/update', params)
            .then(() => {
              this.$router.push({name: 'panel_merchants'})
            })
            .catch(this.catch)
        } else {
          this.$router.push({name: 'panel_merchants'})
        }
      },
      logout() {
        this.$store.dispatch('logout')
          .then(() => {
            this.$router.push({name: 'home'})
          })
      },
      catch(e) {
        switch (e) {
          case 'INVALID_NID':
            this.serverError = 'کد ملی معتبر نیست'
            break
          case 'INVALID_BIRTHDATE':
            this.serverError = 'تاریخ تولد معتبر نیست'
            break
          case 'INVALID_IBAN':
            this.serverError = 'شبا معتبر نیست'
            break
          case 'INVALID_EMAIL':
            this.serverError = 'ایمیل معتبر نیست'
            break
          case 'INVALID_REFERRAL':
            this.serverError = 'کد معرف معتبر نیست'
            break
          default:
            this.serverError = e
        }
        this.snackbar = true
      },
    },
    created() {
      this.$store.subscribe(mutation => {
        switch (mutation.type) {
          case 'profile/set':
          case 'profile/update':
            this.loadProfile()
            break
        }
      })

      this.loadProfile()
    },
    mounted() {
      this.$emit('navState', 'profile')
    }
  }
</script>

<style>
</style>