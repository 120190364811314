<template>
  <div>

    <v-dialog
        :value="value"
        @input="$emit('input', $event)"
        max-width="600px">

      <v-card>
        <v-card-title class="title">
          دریافت کلید جدید
        </v-card-title>

        <v-card-text>
          انجام تراکنش تا زمانی که کلید جدید را جایگزین نکنید، امکانپذیر نخواهد
          بود.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="success darken-1"
              text
              @click="renewMerchantKey">
            <v-icon v-if="loading"
                    class="mdi mdi-loading mdi-spin"></v-icon>
            کلید جدید
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
              color="error darken-1"
              text
              @click="$emit('input', false)">
            پشیمان شدم
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <dialog-merchant-key
        v-model="keyDialog"
        :title="createdMerchant.title"
        :api_key="createdMerchant.api_key"/>

  </div>
</template>

<script>
  import DialogMerchantKey from './DialogMerchantKey'
  import {mapGetters} from 'vuex'

  export default {
    name: "DialogMerchantKeyRenew",
    components: {
      DialogMerchantKey,
    },
    props: [
      'value',
      'merchant',
    ],
    data: () => ({
      loading: false,
      keyDialog: false,
    }),
    computed: {
      ...mapGetters('merchant', {
        createdMerchant: 'created',
      }),
    },
    methods: {
      renewMerchantKey() {
        this.loading = true
        this.$store.dispatch('merchant/renewKey', {
          merchant_id: this.merchant,
        }).then(() => {
          this.loading = false
          this.$emit('input', false)
          this.keyDialog = true
        })
      },
    },
  }
</script>

<style scoped>

</style>