<script>
  import { Line } from 'vue-chartjs'
  import { toPersianDigits } from '../base/number'
  import { formatNumber } from '../base/format'

  export default {
    name: "ChartPaymentsDaily",
    extends: Line,
    props: ['labels', 'count', 'total', 'maxCount', 'maxTotal', 'mobile'],
    data: function() { return {
      chartdata: {
        labels: this.labels.map(l => l.short),
        datasets: [
          {
            label: 'تعداد',
            borderColor: '#49b4af',
            pointBackgroundColor: '#49b4af',
            pointBorderColor: '#49b4af',
            data: this.count,
            yAxisID: 'count',
          },
          {
            label: 'میلیون ریال',
            borderColor: '#f6b437',
            pointBackgroundColor: '#f6b437',
            pointBorderColor: '#f6b437',
            data: this.total,
            yAxisID: 'total',
          }
        ]
      },
      options: {
        elements: {
          point: {
            radius: 2,
          },
          line: {
            fill: false,
            borderWidth: 1.5,
          }
        },
        labels: {
          fontFamily: 'Vazir',
        },
        legend: {
          display: this.mobile,
          position: 'bottom',
          rtl: true,
          labels: {
            boxWidth: 14,
            fontSize: 14,
            fontFamily: 'Vazir',
          },
        },
        tooltips: {
          mode: 'index',
          intersect: false,
          titleFontFamily: 'Vazir',
          titleFontSize: 14,
          bodyFontFamily: 'Vazir',
          bodyFontSize: 12,
          bodyAlign: 'right',
          rtl: true,
          callbacks: {
            title: (tooltipItems) => {
              return this.labels[tooltipItems[0].index].long
            },
            label: function(tooltipItem) {
              switch (tooltipItem.datasetIndex) {
                case 0: // count
                  return toPersianDigits(tooltipItem.value) + ' پرداخت'
                case 1: // total
                  return toPersianDigits(formatNumber(tooltipItem.value)) + ' ریال'
              }
            },
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        fontFamily: 'Vazir',
        scales: {
          xAxes: [{
            ticks: {
              fontFamily: 'Vazir',
              callback: function(value) {
                return toPersianDigits(value);
              },
            },
          }],
          yAxes: [{
            id: 'count',
            scaleLabel: {
              display: !this.mobile,
              labelString: 'تعداد',
              fontFamily: 'Vazir',
              fontColor: '#49b4af',
              fontSize: 14,
            },
            type: 'linear',
            display: true,
            position: 'right',
            ticks: {
              beginAtZero: true,
              suggestedMax: Math.max(this.maxCount, 10),
              fontFamily: 'Vazir',
              callback: function(value) {
                if (!Number.isInteger(value))
                  return ''
                return toPersianDigits(value);
              },
            },
            gridLines: {
              drawOnChartArea: false,
            },
          }, {
            id: 'total',
            scaleLabel: {
              display: !this.mobile,
              labelString: 'میلیون ریال',
              fontFamily: 'Vazir',
              fontColor: '#f6b437',
              fontSize: 14,
            },
            type: 'linear',
            display: true,
            position: 'left',
            ticks: {
              beginAtZero: true,
              suggestedMax: Math.max(this.maxTotal, 10000),
              fontFamily: 'Vazir',
              callback: function(value) {
                return toPersianDigits(value/1000000);
              },
            },
            gridLines: {
              drawOnChartArea: false,
            },
          }],
        }
      }
    }},
    watch: {
      count(c) {
        this.chartdata.datasets[0].data = c
        this.renderChart(this.chartdata, this.options)
      },
      total(t) {
        this.chartdata.datasets[1].data = t
        this.renderChart(this.chartdata, this.options)
      },
      labels(l) {
        this.chartdata.labels = l.map(l => l.short)
        this.renderChart(this.chartdata, this.options)
      },
      mobile(m) {
        this.options.legend.display = m
        this.options.scales.yAxes[0].scaleLabel.display = !m
        this.options.scales.yAxes[1].scaleLabel.display = !m
        this.renderChart(this.chartdata, this.options)
      }
    },
    mounted () {
      this.renderChart(this.chartdata, this.options)
    },
  }
</script>

