<template>
  <v-container>

    <v-app-bar app color="white">
      <v-row align="center">
        <v-col cols="2">
          <v-app-bar-nav-icon
              class="d-flex d-md-none"
              @click="$emit('toggleNavBar')"/>
        </v-col>

        <v-col cols="8">
          <v-toolbar-title
              v-if="this.merchant != null"
              class="text-center">
            {{ this.title }}
          </v-toolbar-title>
        </v-col>

        <v-col cols="2">
          <v-spacer/>
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn depressed :icon="$vuetify.breakpoint.smAndDown" v-on="on">
                <v-icon class="mdi mdi-dots-vertical"></v-icon>
                <span class="pa-2 d-none d-md-flex">تنظیمات</span>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="showRenewMerchantKeyDialog = true">
                <v-list-item-title>کلید جدید</v-list-item-title>
              </v-list-item>

              <v-list-item @click="showDeleteMerchantDialog = true">
                <v-list-item-title>حذف فروشگاه</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
    </v-app-bar>

    <new-merchant-dialog
        v-if="merchant"
        v-model="editMerchant"
        mode="edit"
        :default_merchant_id="merchant.merchant.merchant_id"
        :default_title="merchant.merchant.title"
        :default_website="merchant.merchant.web_site"
        :default_phone="merchant.merchant.phone"
        :default_postalCode="merchant.merchant.postal_code"
        :default_address="merchant.merchant.address"
        :default_nationalCode="merchant.merchant.nid"
        :default_birthdate="merchant.merchant.birthdate"
        :default_iban="merchant.merchant.iban"
        :default_email="merchant.merchant.email"
        :default_wage_payer_party="merchant.merchant.wage_payer_party"
    />

    <delete-merchant-dialog
        v-if="merchant"
        v-model="showDeleteMerchantDialog"
        :merchant="merchant.merchant.merchant_id"
    />

    <renew-merchant-key-dialog
        v-if="merchant"
        v-model="showRenewMerchantKeyDialog"
        :merchant="merchant.merchant.merchant_id"
    />

    <dialog-wallet-tnx
        v-if="merchant"
        v-model="showWalletTnxDialog"
        :merchant="merchant.merchant.merchant_id"
        :reference="(generateReference())"
        @close="loadWalletReport(merchant.merchant.merchant_id)"
    />

    <dialog-wallet-address
        v-if="merchant"
        v-model="showWalletAddressDialog"
        :address="merchant.merchant.merchant_id"
        :title="merchant.merchant.title"
    />

    <v-container>
      <v-card>
        <v-card-title>
          خلاصه وضعیت
        </v-card-title>
        <v-card-text>
          <v-container>
            <!-- <v-row dense> -->
              <v-card>
                <v-card-text class="teal lighten-4">
                  <v-row class="rtl teal lighten-4">
                    <v-col cols="auto" class="teal lighten-4">
                      مجموع بستانکاری معادل <b><span> {{ toPersianDigits(formatNumber(creditorTotal - creditorWage)) }} </span></b>
                      ریال بابت <b><span> {{ toPersianDigits(formatNumber(creditorCount)) }}</span></b> تراکنش
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            <!-- </v-row> -->
            <br/>
            <v-row>
              <v-col cols="12" md="6">
                <div class="subtitle-1 font-weight-medium text-center black--text">پرداخت</div>
                <v-data-table
                    :headers="paymentTable.headers"
                    :items="paymentSummary"
                    :loading="loadingPaymentSummary"
                    disable-pagination
                    disable-filtering
                    disable-sort
                    hide-default-footer
                    mobile-breakpoint="0">
                </v-data-table>
              </v-col>

              <v-col cols="12" md="6">
                <div class="subtitle-1 font-weight-medium text-center black--text">تسویه</div>
                <v-data-table
                    :headers="transferTable.headers"
                    :items="transferSummary"
                    :loading="loadingTransferSummary"
                    disable-pagination
                    disable-filtering
                    disable-sort
                    hide-default-footer
                    mobile-breakpoint="0">
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-container>

    <v-container v-if="useWallet">
      <v-card>
        <v-card-title>
              کیف پول
            <v-spacer/>
            <router-link :to="{ name: 'merchant_wallet_tnxs_report' }">
              <v-btn text small rounded color="primary">
                گزارش کامل تراکنش‌های کیف پول
              </v-btn>
            </router-link>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row align="center">
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="walletBalance"
                  label="مانده (ریال)"
                  readonly
                  outlined
                  filled
                  disabled
                  dense
                  hide-details
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field
                  v-model="walletCash"
                  label="قابل برداشت (ریال)"
                  readonly
                  outlined
                  filled
                  disabled
                  dense
                  hide-details
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                  <v-btn class="button" color="primary"
                          @click="openWalletTnxDialog">
                    برداشت
                  </v-btn>


                  <v-btn class="button" color="primary"
                          @click="openWalletAddressDialog">
                    واریز
                  </v-btn>
              </v-col>
            </v-row>

            <v-row id="report-table">
              <v-col cols="12">
                <div class="subtitle-1 font-weight-medium text-center black--text">آخرین تراکنش‌ها</div>
                <v-data-table
                    :headers="walletData.walletTnxHeaders"
                    :items="walletTnxTable"
                    :loading="loadingWalletReport"
                    item-key="TransactionId"
                    disable-pagination
                    disable-filtering
                    disable-sort
                    hide-default-footer>

                  <template v-slot:item.RequestTime="{ item }">
                    <div class="text-right" style="direction: ltr">
                      {{ formatDate(item.RequestTime) }}
                    </div>
                  </template>
                  <template v-slot:item.SettlementEstimate="{ item }">
                    <div class="text-right" style="direction: ltr">
                      {{ formatDate(item.SettlementEstimate) }}
                    </div>
                  </template>

                  <template v-slot:item.Iban="{ item }">
                    <div class="text-right" style="direction: ltr">
                      <code>{{ item.Iban }}</code>
                    </div>
                  </template>

                  <template v-slot:item.Name="{ item }">
                    <div class="text-right" style="direction: ltr">
                      {{ item.Name }}
                    </div>
                  </template>

                  <template v-slot:item.Amount="{ item }">
                    <div :class="['text-right', item.Amount >= 0 ? 'p-money' : 'n-money']" style="direction: ltr">
                      {{ toPersianDigits(formatMoney(item.Amount)) }}
                    </div>
                  </template>

                  <template v-slot:item.Wage="{ item }">
                    <div class="text-right n-money" style="direction: ltr">
                      {{ toPersianDigits(formatMoney(-item.Wage)) }}
                    </div>
                  </template>

                  <template v-slot:item.Trace="{ item }">
                    <div v-if="item.Trace == '' && item.Type == 'withdraw'" :class="[withdrawStateColor(item.State)]+'--text'">
                      {{ withdrawState(item.State) }}
                    </div>
                    <div v-else>
                      {{ toPersianDigits(item.Trace) }}
                    </div>
                  </template>
                  <template v-slot:item.TransactionId="{ item }">
                    <v-tooltip v-if="item.Trace != ''" left>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          icon
                          v-on="on"
                          class="ma-2"
                          :href="getReceiptLink(item.TransactionId)"
                          target="_blank"
                          color="grey"
                          dark>
                          <v-icon>
                            fas fa-receipt
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>
                        رسید
                      </span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-col>

            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-container>

    <v-container>
      <v-row>
        <v-col cols="12" md="6">
          <v-card>
            <v-card-title>
              نمودار پرداخت
              <div class="mx-2 subtitle-2">تا روز گذشته</div>
              <v-spacer/>
              <v-btn text small rounded
                     :color="numberOfDaysToShow === 7 ? 'black': 'primary'"
                     @click="showWeek()"
              >هفته
              </v-btn>

              <v-btn text small rounded
                     :color="numberOfDaysToShow === 30 ? 'black': 'primary'"
                     @click="showMonth()"
              >ماه
              </v-btn>
            </v-card-title>
            <v-card-text>
              <daily-payments-chart
                  v-if="paymentDailyCount.length !== 0"
                  style="height: 288px;"
                  :mobile="$vuetify.breakpoint.smAndDown"
                  :count="paymentDailyCount"
                  :max-count="Math.max(...last30DaysPaymentCount)"
                  :total="paymentDailyTotal"
                  :max-total="Math.max(...last30DaysPaymentTotal)"
                  :labels="paymentDailyCountLabels"/>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card>
            <v-card-title>
              پرداخت‌های اخیر
              <v-spacer/>
              <router-link :to="{ name: 'merchant_payment_report' }">
                <v-btn text small rounded color="primary">
                  گزارش کامل
                </v-btn>
              </router-link>
            </v-card-title>
            <v-card-text>
              <v-data-table
                  :headers="last10PaymentsTable.headers"
                  :items="last10PaymentsTable.payments || []"
                  :loading="last10PaymentsTable.payments == null"
                  disable-pagination
                  disable-filtering
                  disable-sort
                  hide-default-footer>

                <template v-slot:item.pay_pan="{ item }">
                  <div style="direction: ltr">
                    <code>{{ formatPan(item.pay_pan) }}</code>
                  </div>
                </template>

                <template v-slot:item.total="{ item }">
                  <div class="text-right" style="direction: ltr">
                    {{ toPersianDigits(formatNumber(item.total)) }}
                  </div>
                </template>

                <template v-slot:item.tns_state="{ item }">
                  <div :class="[transferStateColor(item.tns_state)]+'--text'">
                    {{ transferState(item.tns_state) }}
                  </div>
                </template>

              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <v-card
          v-if="$vuetify.breakpoint.smAndDown && merchant"
          :loading="merchant == null">
        <v-expansion-panels accordion readonly flat v-model="showAllInfo">
          <v-expansion-panel>

            <v-expansion-panel-header hide-actions class="pb-0">
              <v-container class="pa-0">
                <v-row>
                  <span class="title">
                    اطلاعات فروشگاه
                  </span>
                  <v-spacer/>
                  <v-btn text rounded color="primary"
                         @click="editMerchant = true">
                    ویرایش
                  </v-btn>
                </v-row>
                <v-row class="ltr-input">
                  <v-col>
                    <v-text-field
                        label="شبا"
                        v-model="iban"
                        readonly
                        outlined
                        dense
                        hide-details>
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-expansion-panel-header>

            <v-expansion-panel-content eager>
              <v-container class="pa-0">
                <v-row class="ltr-input">
                  <v-col cols="6">
                    <v-text-field
                        label="کدملی"
                        v-model="nationalCode"
                        readonly
                        outlined
                        dense
                        hide-details
                    ></v-text-field>
                  </v-col>

                  <v-col cols="6">
                    <v-text-field
                        label="تاریخ تولد"
                        v-model="birthdate"
                        readonly
                        outlined
                        dense
                        hide-details
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                        label="ایمیل"
                        v-model="email"
                        readonly
                        outlined
                        dense
                        hide-details
                    ></v-text-field>
                  </v-col>

                  <v-col cols="6">
                    <v-text-field
                        label="کدپستی"
                        v-model="postalCode"
                        readonly
                        outlined
                        dense
                        hide-details
                    ></v-text-field>
                  </v-col>

                  <v-col cols="6">
                    <v-text-field
                        v-model="phone"
                        label="تلفن"
                        readonly
                        outlined
                        dense
                        hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" class="ltr-input">
                    <v-text-field
                        label="وبسایت"
                        v-model="website"
                        readonly
                        outlined
                        dense
                        hide-details
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                        label="آدرس"
                        v-model="address"
                        readonly
                        outlined
                        dense
                        hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-expansion-panel-content>

          </v-expansion-panel>

        </v-expansion-panels>

        <v-container>
          <v-row>
            <v-spacer/>
            <v-btn text small rounded color="primary"
                   @click="showAllInfo = (showAllInfo+1)*-1">
              <span v-if="showAllInfo === -1">نمایش تمام اطلاعات</span>
              <span v-else>نمایش بخشی از اطلاعات</span>
            </v-btn>
            <v-spacer/>
          </v-row>
        </v-container>
      </v-card>

      <v-card v-else-if="merchant">
        <v-card-title>
          اطلاعات فروشگاه
          <v-spacer/>
          <v-btn text rounded color="primary" @click="editMerchant = true">
            ویرایش
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row class="ltr-input">
              <v-col cols="12" md="4">
                <v-text-field
                    label="شبا"
                    v-model="iban"
                    readonly
                    outlined
                    dense
                    hide-details>
                </v-text-field>
              </v-col>

              <v-col cols="6" md="4">
                <v-text-field
                    label="کدملی"
                    v-model="nationalCode"
                    readonly
                    outlined
                    dense
                    hide-details
                ></v-text-field>
              </v-col>

              <v-col cols="6" md="4">
                <v-text-field
                    label="تاریخ تولد"
                    v-model="birthdate"
                    readonly
                    outlined
                    dense
                    hide-details
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row class="ltr-input">
              <v-col cols="12" md="4">
                <v-text-field
                    label="ایمیل"
                    v-model="email"
                    readonly
                    outlined
                    dense
                    hide-details
                ></v-text-field>
              </v-col>

              <v-col cols="6" md="4">
                <v-text-field
                    label="کدپستی"
                    v-model="postalCode"
                    readonly
                    outlined
                    dense
                    hide-details
                ></v-text-field>
              </v-col>

              <v-col cols="6" md="4">
                <v-text-field
                    v-model="phone"
                    label="تلفن"
                    readonly
                    outlined
                    dense
                    hide-details
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="4" class="ltr-input">
                <v-text-field
                    label="وبسایت"
                    v-model="website"
                    readonly
                    outlined
                    dense
                    hide-details
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="8">
                <v-text-field
                    label="آدرس"
                    v-model="address"
                    readonly
                    outlined
                    dense
                    hide-details
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6" class="ltr-input">
                <v-switch
                    label="کارمزد به عهده پرداخت‌کننده"
                    v-model="wageByPayer"
                    color="red"
                    disabled
                    dense
                    :persistent-hint="true"
                    :hint="hint"
                    hide-details="auto"
                ></v-switch>
              </v-col>
            </v-row>

          </v-container>
        </v-card-text>
      </v-card>
    </v-container>

  </v-container>
</template>

<script>
  import {mapActions, mapGetters} from 'vuex'
  import {withdrawState, withdrawStateColor} from './base/wallet'
  import {toPersianDigits} from './base/number'
  import {formatNumber, formatMoney, formatPan, formatIban, formatDate} from './base/format'
  import NewMerchantDialog from './DialogMerchantNew'
  import DeleteMerchantDialog from './DialogMerchantDelete'
  import RenewMerchantKeyDialog from './DialogMerchantKeyRenew'
  import DialogWalletTnx from './DialogWalletTnx'
  import DialogWalletAddress from './DialogWalletAddress'
  import DailyPaymentsChart from './reports/ChartPaymentsDaily'
  import {toJalaali, toGregorian} from 'jalaali-js'
  import { uuid } from 'vue-uuid'

  export default {
    name: "PanelMerchantDetail",
    components: {
      NewMerchantDialog,
      DeleteMerchantDialog,
      RenewMerchantKeyDialog,
      DailyPaymentsChart,
      DialogWalletTnx,
      DialogWalletAddress,
    },
    data: () => ({
      merchant: null,
      creditorCount: 0,
      creditorTotal: 0,
      creditorWage: 0,

      paymentTable: {
        headers: [{
          text: 'زمان',
          align: 'start',
          sortable: false,
          value: 'name',
        },
          {text: 'تعداد', value: 'count'},
          {text: 'مبلغ (ریال)', value: 'total'},
          {text: 'کارمزد (ریال)', value: 'wage'},
        ],
        todaySummary: {},
        thisWeekSummary: {},
        thisMonthSummary: {},
        thisYearSummary: {},
        historySummary: {},
      },
      last10PaymentsTable: {
        headers: [
          {text: 'شماره کارت', value: 'pay_pan', align: 'right'},
          {text: 'مبلغ (ریال)', value: 'total', align: 'right'},
          {text: 'وضعیت تسویه', value: 'tns_state', align: 'right'},
        ],
        payments: null,
      },
      transferTable: {
        headers: [{
          text: 'زمان',
          align: 'start',
          sortable: false,
          value: 'name',
        },
          {text: 'تسویه شده (ریال)', value: 'transferredAmount'},
          {text: 'در انتظار تسویه (ریال)', value: 'waitingAmount'},
          {text: 'تسویه ناموفق (ریال)', value: 'failedAmount'},
        ],
        todaySummary: {},
        thisWeekSummary: {},
        thisMonthSummary: {},
        thisYearSummary: {},
        historySummary: {},
      },
      walletData: {
        balance: 0,
        cash: 0,
        walletTnxHeaders: [
          {text: 'تاریخ ثبت', value: 'RequestTime'},
          {text: 'زمان تقریبی تسویه', value: 'SettlementEstimate'},
          {text: 'شبا / کیف پول', value: 'Iban'},
          {text: 'نام', value: 'Name'},
          {text: 'مبلغ (ریال)', value: 'Amount'},
          {text: 'کارمزد (ریال)', value: 'Wage'},
          {text: 'کد رهگیری', value: 'Trace'},
          {text: '', value: 'TransactionId'},
        ],
        report: {},
        walletReportReady: false,
      },
      last30DaysPaymentCount: [],
      last30DaysPaymentTotal: [],
      last30DaysPaymentLabels: [],
      numberOfDaysToShow: 7,
      editMerchant: false,
      showAllInfo: -1,
      showDeleteMerchantDialog: false,
      showRenewMerchantKeyDialog: false,
      showMerchantKeyDialog: false,
      showWalletTnxDialog: false,
      showWalletAddressDialog: false,
    }),
    computed: {
      ...mapGetters({
        sessionKey: 'sessionKey',
        getMerchant: 'merchant/merchant',
        getPaymentSummary: 'merchant/payment/summary/get',
        getTransferSummary: 'merchant/transferSummary/get',
        getPaymentDaily: 'merchant/payment/daily/get',
        getPaymentDetail: 'merchant/payment/detail/get',
        getWalletReport: 'merchant/walletReport/get',
      }),
      title() {
        return this.merchant.merchant.title
      },
      nationalCode() {
        return toPersianDigits(this.merchant.merchant.nid)
      },
      birthdate() {
        return toPersianDigits(this.merchant.merchant.birthdate)
      },
      email() {
        return this.merchant.merchant.email
      },
      postalCode() {
        return toPersianDigits(this.merchant.merchant.postal_code)
      },
      phone() {
        return toPersianDigits(this.merchant.merchant.phone)
      },
      iban() {
        return toPersianDigits(this.merchant.merchant.iban)
      },
      website() {
        return this.merchant.merchant.web_site
      },
      address() {
        return this.merchant.merchant.address
      },
      wageByPayer() {
        return this.merchant.merchant.wage_payer_party == 'payer'
      },
      hint() {
        return this.wageByPayer ? 'مبلغ کارمزد به مبلغ اصلی اضافه شده و پرداخت کننده باید مجموع آن را بپردازد.' : ''
      },
      walletBalance() {
        return toPersianDigits(formatNumber(this.walletData.report.Balance))
      },
      walletCash() {
        return toPersianDigits(formatNumber(this.walletData.report.Cash))
      },
      useWallet() {
        return this.merchant != undefined ? this.merchant.merchant.use_wallet : false
      },
      walletAddress() {
        return this.merchant != undefined ? this.merchant.merchant.merchant_id : ''
      },
      loadingPaymentSummary() {
        return !(this.paymentTable.todaySummary.name)
      },
      paymentSummary() {
        return [
          this.paymentTable.todaySummary,
          this.paymentTable.thisWeekSummary,
          this.paymentTable.thisMonthSummary,
          this.paymentTable.thisYearSummary,
          this.paymentTable.historySummary,
        ]
      },
      loadingTransferSummary() {
        return !(this.transferTable.todaySummary.name)
      },
      transferSummary() {
        return [
          this.transferTable.todaySummary,
          this.transferTable.thisWeekSummary,
          this.transferTable.thisMonthSummary,
          this.transferTable.thisYearSummary,
          this.transferTable.historySummary,
        ]
      },
      loadingWalletReport() {
        return ! (this.walletData.walletReportReady)
      },
      walletTnxTable() {
        return this.walletData.report.WalletTnxs
      },
      paymentDailyCount() {
        return this.last30DaysPaymentCount.slice(
          -1 * this.numberOfDaysToShow,
          this.last30DaysPaymentCount.length,
        )
      },
      paymentDailyTotal() {
        return this.last30DaysPaymentTotal.slice(
          -1 * this.numberOfDaysToShow,
          this.last30DaysPaymentTotal.length,
        )
      },
      paymentDailyCountLabels() {
        return this.last30DaysPaymentLabels.slice(
          -1 * this.numberOfDaysToShow,
          this.last30DaysPaymentLabels.length,
        )
      }
    },
    methods: {
      ...mapActions({
        loadMerchants: 'merchant/load',
      }),
      toPersianDigits,
      formatNumber,
      formatMoney,
      formatPan,
      formatIban,
      formatDate,
      withdrawState,
      withdrawStateColor,

      transferState(state) {
        switch (state) {
          case 'done':
            return 'تسویه شده'
          case 'shaparak_ongoing':
            return 'در حال تسویه'
          case 'shaparak_error':
            return 'خطا در تسویه'
          default:
            return '-'
        }
      },

      transferStateColor(state) {
        switch (state) {
          case 'done':
            return 'green'
          case 'shaparak_error':
            return 'red'
          default:
            return ''
        }
      },

      generateReference()  {
        return uuid.v4().toString();
      },

      loadReports(merchantId) {
        let now = new Date()
        let today = new Date(now.toDateString())

        // Find the previous Saturday, if it's not today
        let d = today.getDay()
        d = (d == 6) ? 0 : d + 1
        let weekStart = new Date(today.valueOf() - d * 86400000)

        let jNow = toJalaali(now)
        let m = toGregorian(jNow.jy, jNow.jm, 1)
        let y = toGregorian(jNow.jy, 1, 1)

        let monthStart = new Date(m.gy, m.gm - 1, m.gd)
        let yearStart = new Date(y.gy, y.gm - 1, y.gd)
        let historyStart = new Date(1970, 1, 1)

        this.$store.dispatch('merchant/payment/summary/load', {
          tag: merchantId + 'today',
          params: {
            merchant_id: merchantId,
            label: 'today',
            time_1: today,
            time_2: weekStart,
            time_3: monthStart,
            time_4: yearStart,
            time_5: historyStart,
          },
        }).then(() => {
          const s = this.getPaymentSummary(merchantId + 'today')

          this.paymentTable.todaySummary = {
            name: 'امروز',
            count: toPersianDigits(formatNumber(s.row.count_1)),
            total: toPersianDigits(formatNumber(s.row.total_1)),
            wage: toPersianDigits(formatNumber(s.row.wage_1)),
          }
          this.paymentTable.thisWeekSummary = {
            name: 'این هفته',
            count: toPersianDigits(formatNumber(s.row.count_2)),
            total: toPersianDigits(formatNumber(s.row.total_2)),
            wage: toPersianDigits(formatNumber(s.row.wage_2)),
          }
          this.paymentTable.thisMonthSummary = {
            name: 'این ماه',
            count: toPersianDigits(formatNumber(s.row.count_3)),
            total: toPersianDigits(formatNumber(s.row.total_3)),
            wage: toPersianDigits(formatNumber(s.row.wage_3)),
          }
          this.paymentTable.thisYearSummary = {
            name: 'امسال',
            count: toPersianDigits(formatNumber(s.row.count_4)),
            total: toPersianDigits(formatNumber(s.row.total_4)),
            wage: toPersianDigits(formatNumber(s.row.wage_4)),
          }
          this.paymentTable.historySummary = {
            name: 'از آغاز',
            count: toPersianDigits(formatNumber(s.row.count_5)),
            total: toPersianDigits(formatNumber(s.row.total_5)),
            wage: toPersianDigits(formatNumber(s.row.wage_5)),
          }
        })

        let last30Days = new Date(today)
        last30Days.setDate(last30Days.getDate() - 30)

        this.$store.dispatch('merchant/transferSummary/load', {
          merchant_id: merchantId,
          label: 'today',
          time_1: today,
          time_2: weekStart,
          time_3: monthStart,
          time_4: yearStart,
          time_5: historyStart,
        }).then(() => {
          const s = this.getTransferSummary(merchantId)
          
          this.creditorCount = s.creditor.count
          this.creditorTotal = s.creditor.total
          this.creditorWage = s.creditor.wage

          this.transferTable.todaySummary = {
            name: 'امروز',
            transferredCount: toPersianDigits(formatNumber(s.transferred_count_1)),
            transferredAmount: toPersianDigits(formatNumber(s.transferred_amount_1)),
            waitingCount: toPersianDigits(formatNumber(s.waiting_count_1)),
            waitingAmount: toPersianDigits(formatNumber(s.waiting_amount_1)),
            failedCount: toPersianDigits(formatNumber(s.failed_count_1)),
            failedAmount: toPersianDigits(formatNumber(s.failed_amount_1)),
          }

          this.transferTable.thisWeekSummary = {
            name: 'این هفته',
            transferredCount: toPersianDigits(formatNumber(s.transferred_count_2)),
            transferredAmount: toPersianDigits(formatNumber(s.transferred_amount_2)),
            waitingCount: toPersianDigits(formatNumber(s.waiting_count_2)),
            waitingAmount: toPersianDigits(formatNumber(s.waiting_amount_2)),
            failedCount: toPersianDigits(formatNumber(s.failed_count_2)),
            failedAmount: toPersianDigits(formatNumber(s.failed_amount_2)),
          }

          this.transferTable.thisMonthSummary = {
            name: 'این ماه',
            transferredCount: toPersianDigits(formatNumber(s.transferred_count_3)),
            transferredAmount: toPersianDigits(formatNumber(s.transferred_amount_3)),
            waitingCount: toPersianDigits(formatNumber(s.waiting_count_3)),
            waitingAmount: toPersianDigits(formatNumber(s.waiting_amount_3)),
            failedCount: toPersianDigits(formatNumber(s.failed_count_3)),
            failedAmount: toPersianDigits(formatNumber(s.failed_amount_3)),
          }

          this.transferTable.thisYearSummary = {
            name: 'امسال',
            transferredCount: toPersianDigits(formatNumber(s.transferred_count_4)),
            transferredAmount: toPersianDigits(formatNumber(s.transferred_amount_4)),
            waitingCount: toPersianDigits(formatNumber(s.waiting_count_4)),
            waitingAmount: toPersianDigits(formatNumber(s.waiting_amount_4)),
            failedCount: toPersianDigits(formatNumber(s.failed_count_4)),
            failedAmount: toPersianDigits(formatNumber(s.failed_amount_4)),
          }

          this.transferTable.historySummary = {
            name: 'از آغاز',
            transferredCount: toPersianDigits(formatNumber(s.transferred_count_5)),
            transferredAmount: toPersianDigits(formatNumber(s.transferred_amount_5)),
            waitingCount: toPersianDigits(formatNumber(s.waiting_count_5)),
            waitingAmount: toPersianDigits(formatNumber(s.waiting_amount_5)),
            failedCount: toPersianDigits(formatNumber(s.failed_count_5)),
            failedAmount: toPersianDigits(formatNumber(s.failed_amount_5)),
          }
        })

        this.$store.dispatch('merchant/payment/daily/load', {
            tag: merchantId + 'last30days',
            params: {
              merchant_id: merchantId,
              since: last30Days,
              until: now,
              offset: 0,
              limit: 50,
            },
          },
        ).then(() => {
          let data = this.getPaymentDaily(merchantId + 'last30days')
          if (data == null)
            return

          const months = [
            'فروردین', 'اردیبهشت', 'خرداد', 'تیر',
            'مرداد', 'شهریور', 'مهر', 'آبان',
            'آذر', 'دی', 'بهمن', 'اسفند',
          ]

          this.last30DaysPaymentCount = []
          this.last30DaysPaymentTotal = []
          this.last30DaysPaymentLabels = []
          for (let i = data.rows.length - 1; i >= 0; i--) {
            this.last30DaysPaymentCount.push(data.rows[i].total_count)
            this.last30DaysPaymentTotal.push(data.rows[i].total_amount)

            // todo: move it out of here or use a library.

            const d = new Date(data.rows[i].date)
            const j = toJalaali(d)
            this.last30DaysPaymentLabels.push({
              short: toPersianDigits(j.jm + '/' + j.jd),
              long: toPersianDigits(j.jd + ' ' + months[j.jm-1]),
            })
          }
        })

        this.$store.dispatch('merchant/payment/detail/load', {
          tag: merchantId + 'last10payments',
          params: {
            merchant_id: merchantId,
            offset: 0,
            limit: 5,
          },
        }).then(() => {
          let payments = this.getPaymentDetail(merchantId + 'last10payments')
          if (payments == null) {
            return
          }

          this.last10PaymentsTable.payments = []
          for (let i = 0; i < payments.rows.length; i++) {
            this.last10PaymentsTable.payments.push({
              pay_pan: payments.rows[i].pay_pan,
              total: payments.rows[i].total,
              tns_state: payments.rows[i].tns_state,
            })
          }
        })

        this.loadWalletReport(merchantId)
      },

      loadWalletReport(merchantId) {
        this.walletData.walletReportReady = false
        this.$store.dispatch('merchant/walletReport/load', {
          tag: merchantId + 'wallet',
          params: {
            merchant_id: merchantId,
            since: new Date(0),
            until: new Date(),
            offset: 0,
            limit: 3,
          }
        }).then(() => {
          this.walletData.report = this.getWalletReport(merchantId)
          this.walletData.walletReportReady = true
        })
      },

      showMonth() {
        this.numberOfDaysToShow = 30
      },

      showWeek() {
        this.numberOfDaysToShow = 7
      },

      openWalletTnxDialog() {
        this.showWalletTnxDialog = true
      },

      openWalletAddressDialog() {
        this.showWalletAddressDialog = true
      },

      getReceiptLink(transactionId){
        return `/api/owner/wallet_tnx_receipt?transaction_id=${transactionId}`
      },

      copyAddress() {
        const e = document.getElementById('wallet-address')
        e.select()
        e.setSelectionRange(0, this.walletAddress.length)

        this.$copyText(this.walletAddress).then(function () {
          e.setSelectionRange(0, 0)
        })

        this.$store.commit('showCopiedSnackbar')
      },
    },

    created() {
      // this.merchantId = this.$route.params.id
      this.merchantId = this.$store.state.currentMerchantId

      this.$store.subscribe(mutation => {
        if (mutation.type === 'merchant/set') {
          this.merchant = this.getMerchant(this.merchantId)
        }
      })

      this.merchant = this.getMerchant(this.merchantId)
      this.loadReports(this.merchantId)
    },
    mounted() {
      this.$emit('navState', 'merchant_detail')
    }
  }
</script>

<style scoped>

.button {
  margin: 8px;
}

#report-table {
  margin-top: 36px;
}

</style>
