const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
function validEmail(address) {
  return emailRegex.test(address)
}

function validEmailRule(address) {
  if (validEmail(address))
    return true
  return 'ایمیل نامعتبر است'
}

export {
  validEmail,
  validEmailRule,
}
